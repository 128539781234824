import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";

import get from "lodash/get";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import {
  FormSelect,
  FormCheckbox,
  FormCreatableSelect,
  FormDateTimePicker,
  FormDatePicker,
  FormFileField,
  FormChipSelect,
} from "../FormFields";

export const FIELD_TYPES = {
  Input: "input",
  NumericInput: "numericinput",
  TextArea: "textArea",
  CheckBox: "checkBox",
  DateTime: "datetime",
  Date: "date",
  FileUpload: "upload",
  ChipSelect: "chipSelect",
  // single select
  Select: "select",
  // handles multiple values as commas separated string
  SelectMulti: "selectMulti",
  // handles multiple values in an array
  SelectMultiArray: "selectMultiArray",
  SelectCreatable: "selectCreatable",
  // custom fields
  ConfigSelect: "configSelect",
  CustomField: "customField",
};

/**
 * Render dynamicall generated formConfig based forms
 *
 * @formConfigs {sections: { title, fieldConfigs: [ { field_key, label, field_type } ] } }
 * @data initial data for edit forms, will have some extra feilds that may not be shown in form but will need to submit to server ex. geometry, associations
 */
const DynamicForm = forwardRef((props, ref) => {
  const {
    formConfigs,
    data,
    onSubmit,
    onCancel,
    isEdit = false,
    isLoading,
    configurationOptions = [],
  } = props;
  const { sections, dependencyFields = [] } = formConfigs;

  const {
    formState: { errors },
    register,
    control,
    watch,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({ defaultValues: data, shouldUnregister: true });

  useImperativeHandle(ref, () => ({
    onError: (fieldKey, errorMsg) => {
      setError(fieldKey, { type: "custom", message: errorMsg });
    },
  }));

  const onFormSubmit = useCallback(
    (formData) => {
      // get all the form data, add other data that will not be in formData such as geometry, associations
      onSubmit({ ...data, ...formData }, setError, clearErrors);
    },
    [data, onSubmit]
  );

  // watchValues : [0: true, 1: "abc"] => return value list according to dependencyFields
  const watchValues = watch(dependencyFields);
  // convert value list to {key, value}
  const watchValuesKeyValues = useMemo(() => {
    let result = {};
    dependencyFields.forEach((field, i) => (result[field] = watchValues[i]));
    return result;
  }, [watchValues, dependencyFields]);

  return (
    <Box ref={ref} component="form" onSubmit={handleSubmit(onFormSubmit)}>
      <Box p={2}>
        {sections.map((section, s_id) => {
          const { title, fieldConfigs, section_type } = section;

          return (
            <Stack key={s_id} spacing={2}>
              {title ? (
                <>
                  <Stack
                    direction="row"
                    width="100%"
                    pt={s_id === 0 ? undefined : 2}
                  >
                    <Typography color="primary.dark" flex={1} variant="h5">
                      {title}
                    </Typography>
                  </Stack>

                  <Divider />
                </>
              ) : null}

              <Grid container pt={1} pl={0.5} spacing={2} width="100%">
                {fieldConfigs.map((config) => {
                  const {
                    field_key,
                    label,
                    field_type,
                    options = [],
                    validationProps,
                    disabled,
                    disable_on_edit,
                    FormFieldComponent,
                  } = config;

                  const required = !!get(validationProps, "required");
                  const isDisabled = disabled || (disable_on_edit && isEdit);

                  const isHidden = config.isHidden
                    ? config.isHidden(watchValuesKeyValues)
                    : false;

                  if (isHidden) return null;

                  switch (field_type) {
                    case FIELD_TYPES.Input:
                    case FIELD_TYPES.NumericInput:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <TextField
                            className="full-width"
                            label={label}
                            {...register(field_key, validationProps)}
                            disabled={!!isDisabled}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                            type={
                              field_type === FIELD_TYPES.NumericInput
                                ? "number"
                                : "text"
                            }
                            inputProps={{
                              step: 0.0001,
                            }}
                            InputLabelProps={{
                              required,
                            }}
                          />
                        </Grid>
                      );

                    case FIELD_TYPES.TextArea:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <TextField
                            className="full-width"
                            multiline
                            rows={3}
                            label={label}
                            {...register(field_key, validationProps)}
                            disabled={!!isDisabled}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                            InputLabelProps={{
                              required,
                            }}
                          />
                        </Grid>
                      );

                    case FIELD_TYPES.CheckBox:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <FormCheckbox
                            label={label}
                            name={field_key}
                            control={control}
                            rules={validationProps}
                            disabled={!!isDisabled}
                            required={required}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                          />
                        </Grid>
                      );

                    case FIELD_TYPES.ChipSelect:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <FormChipSelect
                            label={label}
                            name={field_key}
                            control={control}
                            rules={validationProps}
                            disabled={!!isDisabled}
                            options={options || []}
                            required={required}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                          />
                        </Grid>
                      );

                    case FIELD_TYPES.Select:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <FormSelect
                            label={label}
                            name={field_key}
                            control={control}
                            rules={validationProps}
                            isDisabled={!!isDisabled}
                            required={required}
                            options={options || []}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                          />
                        </Grid>
                      );

                    case FIELD_TYPES.SelectMulti:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <FormSelect
                            isMulti
                            label={label}
                            name={field_key}
                            control={control}
                            rules={validationProps}
                            isDisabled={!!isDisabled}
                            required={required}
                            options={config.options || []}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                          />
                        </Grid>
                      );

                    case FIELD_TYPES.SelectMultiArray:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <FormSelect
                            isMulti
                            simpleValue={true}
                            label={label}
                            name={field_key}
                            control={control}
                            rules={validationProps}
                            isDisabled={!!isDisabled}
                            required={required}
                            options={config.options || []}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                          />
                        </Grid>
                      );

                    case FIELD_TYPES.SelectCreatable:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <Stack width="100%">
                            <FormCreatableSelect
                              isMulti
                              label={label}
                              name={field_key}
                              labelKey={config.labelKey}
                              valueKey={config.valueKey}
                              control={control}
                              rules={validationProps}
                              isDisabled={!!isDisabled}
                              required={required}
                              options={config.options || []}
                              error={!!get(errors, [field_key])}
                              helperText={get(
                                errors,
                                [field_key, "message"],
                                ""
                              )}
                            />
                          </Stack>
                        </Grid>
                      );

                    case FIELD_TYPES.DateTime:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <Stack width="100%">
                            <FormDateTimePicker
                              label={label}
                              name={field_key}
                              control={control}
                              rules={validationProps}
                              isDisabled={!!isDisabled}
                              error={!!get(errors, [field_key])}
                              helperText={get(
                                errors,
                                [field_key, "message"],
                                ""
                              )}
                            />
                          </Stack>
                        </Grid>
                      );

                    case FIELD_TYPES.Date:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <Stack width="100%">
                            <FormDatePicker
                              label={label}
                              name={field_key}
                              control={control}
                              rules={validationProps}
                              isDisabled={!!isDisabled}
                              error={!!get(errors, [field_key])}
                              helperText={get(
                                errors,
                                [field_key, "message"],
                                ""
                              )}
                            />
                          </Stack>
                        </Grid>
                      );

                    case FIELD_TYPES.FileUpload: {
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <Stack width="100%">
                            <FormFileField
                              label={label}
                              name={field_key}
                              control={control}
                              rules={validationProps}
                              disabled={!!isDisabled}
                              required={required}
                              error={!!get(errors, [field_key])}
                              helperText={get(
                                errors,
                                [field_key, "message"],
                                ""
                              )}
                            />
                          </Stack>
                        </Grid>
                      );
                    }

                    case FIELD_TYPES.CustomField: {
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <FormFieldComponent
                            name={field_key}
                            control={control}
                            rules={validationProps}
                            disabled={!!isDisabled}
                            required={required}
                            error={!!get(errors, [field_key])}
                            helperText={get(errors, [field_key, "message"], "")}
                            isEdit={isEdit}
                          />
                        </Grid>
                      );
                    }

                    default:
                      return (
                        <Grid item xs={12} sm={6} key={field_key}>
                          <div className="full-width">
                            <div>{label}</div>
                          </div>
                        </Grid>
                      );
                  }
                })}
              </Grid>

              <Divider sx={{ pt: 2 }} />
            </Stack>
          );
        })}
      </Box>
      <Stack p={3} spacing={3} direction="row">
        <Button sx={{ minWidth: "10em" }} color="error" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          sx={{ minWidth: "10em" }}
          variant="contained"
          disableElevation
          color="success"
          type="submit"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Box>
  );
});

export default DynamicForm;
