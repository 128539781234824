import { createSlice } from "@reduxjs/toolkit";

import get from "lodash/get";
import find from "lodash/find";
import size from "lodash/size";

import { AVAILABLE_DASHBOARDS } from "pages/Dashboard/dashboard.utils";

const initialState = {
  token: "",
  user: {},
  isAdmin: false,
  permissions: {},
  // list of dash_configs user has acces to
  // shape: { permission_key, dash_key, label }
  availableDashboards: [],
  // 1> user last visited dash, 2> on login first dash from list, 3> null if user has no dash access
  // use this when user directly goes to "/" path without explicitly selecting and dashboard from list
  defaultDashboard: null,
  loginSince: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      const { permissions } = payload;
      state.token = payload.token;
      state.user = payload.user;
      state.permissions = permissions;

      const isSuperAdminUser = get(payload, "user.is_superuser");
      // admin or superadmin can view
      state.isAdmin = !!(get(payload, "user.is_staff") || isSuperAdminUser);
      state.loginSince = Number(new Date());
      // check user available dashboards
      let availableDashboards = []; // list of dash_keys
      for (let dashInd = 0; dashInd < AVAILABLE_DASHBOARDS.length; dashInd++) {
        // shape: { permission_key, dash_key, label }
        const currDashConf = AVAILABLE_DASHBOARDS[dashInd];
        if (
          isSuperAdminUser ||
          get(permissions, currDashConf.permission_key, false)
        ) {
          availableDashboards.push(currDashConf);
        }
      }
      state.availableDashboards = availableDashboards;
      // handle default dashboard
      if (size(availableDashboards)) {
        // on login set first dash from the AVAILABLE_DASHBOARDS list as default dash
        state.defaultDashboard = availableDashboards[0];
      } else {
        // user has no dashboard access
        state.defaultDashboard = null;
      }
    },
    updateUserDetails: (state, { payload }) => {
      state.user = payload;
    },
    setDefaultDashboard: (state, { payload }) => {
      // payload is dash_key, get config from it
      state.defaultDashboard = find(AVAILABLE_DASHBOARDS, [
        "dash_key",
        payload,
      ]);
    },
    logout: (state) => {
      return initialState;
    },
  },
});

export const { login, logout, updateUserDetails, setDefaultDashboard } =
  authSlice.actions;
export default authSlice.reducer;
