import { createSlice } from "@reduxjs/toolkit";

export const PAGE_SELCTIONS = {
  PLANNING_PAGE: "planning",
  DEVICES_PAGE: "devices",
};

const initialState = {
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  headerHeight: 64,
  contentHeight: window.innerHeight - 64,
  // generally used to check which component to render on planning | devices pages
  currentPage: "",
  version_info: {},
  system_config: {},
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setWindowSize: (state) => {
      state.windowWidth = window.innerWidth;
      state.windowHeight = window.innerHeight;
      state.contentHeight = window.innerHeight - initialState.headerHeight;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setSystemConfig: (state, { payload }) => {
      state.version_info = payload.version_info;
      state.system_config = payload.system_config;
    },
  },
});

export const { setWindowSize, setCurrentPage, setSystemConfig } =
  appStateSlice.actions;
export default appStateSlice.reducer;
