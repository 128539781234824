import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";

import { Box } from "@mui/material";

import GisMapPopups from "../GisMapPopups";
import TableHeader from "../ElementDetailsTable/TableHeader";
import AutoDesignForm from "./AutoDesignForm";

import { setMapState } from "planning/data/planningGis.reducer";
import { fetchLayerListDetails } from "planning/data/actionBar.services";
import { onFetchLayerListDetailsSuccess } from "planning/data/planning.actions";
import { GisElementTableLoader } from "../GisMapPopups/GisMapPopupLoader";

const AutoDesignPopup = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const [minimized, setMinimized] = useState(false);

  // update configurations in redux if not there already
  const { isLoading } = useQuery(
    "planningLayerConfigsDetails",
    fetchLayerListDetails,
    {
      staleTime: Infinity,
      // enabled: isConfigurable,
      onSuccess: (layerConfData) => {
        dispatch(onFetchLayerListDetailsSuccess(layerConfData));
      },
    }
  );

  const handlePopupMinimize = useCallback(() => {
    setMinimized((val) => !val);
  }, [setMinimized]);

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  if (isLoading) {
    return <GisElementTableLoader />;
  }

  return (
    <GisMapPopups dragId="FilterPopup">
      <Box minWidth="450px">
        {/* Table header */}
        <TableHeader
          title="Auto Design Form"
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
        {minimized ? null : <AutoDesignForm closeForm={handleCloseDetails} />}
      </Box>
    </GisMapPopups>
  );
};

export default AutoDesignPopup;
