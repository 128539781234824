import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
} from "../common/configuration";

import size from "lodash/size";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import map from "lodash/map";
import find from "lodash/find";

import { default as Icon } from "assets/markers/sw_box.svg";
import { default as EditIcon } from "assets/markers/sw_pin.svg";
import { FIELD_TYPES } from "components/common/DynamicForm";

import OptionalGroupSelect from "planning/GisMap/layers/common/formComponents/OptionalGroupSelect";
import GeneralGroupSelect from "planning/GisMap/layers/common/formComponents/GeneralGroupSelect";
import TemplateSelect from "planning/GisMap/layers/common/formComponents/TemplateSelect";

import { fetchGroupTagList } from "planning/data/catv/catv.services";
import store from "redux/store";
import { getSingleLayerConfigurationList } from "planning/data/planningState.selectors";

export const LAYER_KEY = "p_switch";
export const PRE_UID = "SW";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

// this will become function -> generate From Configs
export const getElementFormTemplate = (config) => {
  return {
    title: "Switch Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_type: FIELD_TYPES.CustomField,
            field_key: "general_groups",
            FormFieldComponent: GeneralGroupSelect,
          },
          {
            field_type: FIELD_TYPES.CustomField,
            field_key: "optional_groups",
            FormFieldComponent: OptionalGroupSelect,
          },
          {
            field_type: FIELD_TYPES.CustomField,
            field_key: "templates",
            FormFieldComponent: TemplateSelect,
            disable_on_edit: true,
            validationProps: {
              required: "Template is required",
            },
          },
          {
            field_key: "location_name",
            label: "Location Name",
            field_type: FIELD_TYPES.Input,
            disable_on_edit: true,
            validationProps: {
              required: "Location Name is required",
            },
          },
          {
            field_key: "state_name",
            label: "State Name",
            field_type: FIELD_TYPES.Input,
            disable_on_edit: true,
            validationProps: {
              required: "State Name is required",
            },
          },
          {
            field_key: "host_id",
            label: "Device Id",
            field_type: FIELD_TYPES.Input,
            disable_on_edit: true,
          },
          {
            field_key: "ip_address",
            label: "IP address",
            field_type: FIELD_TYPES.Input,
            disable_on_edit: true,
            validationProps: {
              required: "IP address is required",
            },
          },
          {
            field_key: "port",
            label: "Port",
            field_type: FIELD_TYPES.NumericInput,
            disable_on_edit: true,
            validationProps: {
              required: "Port is required",
            },
          },
        ],
      },
    ],
  };
};

export const getElementTableFields = () => {
  // remove unique_id and reff_code from common fields
  const abstractFields = filter(
    ELEMENT_TABLE_ABSTRACT_FIELDS,
    (d) => d.field !== "ref_code" && d.field !== "unique_id"
  );
  return [
    ...abstractFields,
    // {
    //   label: "Groups",
    //   field: "groups",
    //   type: "multi_array",
    // },
    // {
    //   label: "Templates",
    //   field: "templates",
    //   type: "multi_array",
    // },
    { label: "Location Name", field: "location_name", type: "simple" },
    { label: "State Name", field: "state_name", type: "simple" },
    { label: "Model Name", field: "model_name", type: "simple" },
    // { label: "Device Id", field: "host_id", type: "simple" },
    { label: "IP address", field: "ip_address", type: "simple" },
    // { label: "Port", field: "port", type: "simple" },
  ];
};

export const onEditDataConverter = async (elemData) => {
  const configList = getSingleLayerConfigurationList(LAYER_KEY)(
    store.getState()
  );

  const currentConfig = find(configList, ["id", elemData.configuration]);
  const groupGeneralTag = get(currentConfig, "group_general_tag", null);
  const groupOptionalTag = get(currentConfig, "group_optional_tag", null);

  const allGeneralGroupTagList = await fetchGroupTagList(groupGeneralTag);
  const allOptionalGroupTagList = await fetchGroupTagList(groupOptionalTag);
  // filter groups
  let general_groups = filter(allGeneralGroupTagList, (d) =>
    includes(elemData.groups, d.group_id)
  );
  general_groups = map(general_groups, "group_id");

  let optional_groups = filter(allOptionalGroupTagList, (d) =>
    includes(elemData.groups, d.group_id)
  );
  optional_groups = map(optional_groups, "group_id");

  return { ...elemData, general_groups, optional_groups };
};

export const transformAndValidateData = (formData, setError) => {
  let groups = [];

  if (size(formData.general_groups)) {
    groups = groups.concat(formData.general_groups);
  }
  if (size(formData.optional_groups)) {
    groups = groups.concat(formData.optional_groups);
  }

  if (size(groups)) {
    return [
      {
        ...formData,
        groups,
        optional_groups: undefined,
        general_groups: undefined,
      },
      null,
    ];
  } else {
    const errMessage =
      "Groups can not be empty, please add general or optional group";
    setError("general_groups", {
      type: "custom",
      message: errMessage,
    });
    return [null, errMessage];
  }
};

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "show_generic_attrs_table",
  },
  {
    control: "show_interface_attrs_table",
  },
];

export const FILTER_FORM_CONFIG = [];
