import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";

import { Box, Paper, Stack, Button } from "@mui/material";

import size from "lodash/size";

import GisMapPopups from "../GisMapPopups";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

import {
  setMapState,
  setSpecialLayerData,
  updateMapState,
  // setSpecialLayerData,
} from "planning/data/planningGis.reducer";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { postRoutesFromCoords } from "planning/data/planning.services";
import {
  FEATURE_TYPES,
  zIndexMapping,
} from "planning/GisMap/layers/common/configuration";
import { coordsToLatLongMap, getFillColor } from "utils/map.utils";
import { HIGHLIGHTED_POLYLINE_OPTIONS } from "planning/GisMap/utils";
import { updateAutoDesignCable } from "planning/data/event.actions";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import TableHeader from "../ElementDetailsTable/TableHeader";

const ReviewAutoDesign = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */
  const dispatch = useDispatch();
  const mapState = useSelector(getPlanningMapState);
  const [minimized, setMinimized] = useState(false);
  const { shouldApiCall, data: mapStateData } = mapState;
  const { connectionList, cableList } = mapStateData;

  const {
    mutate: postRoutesFromCoordsMutation,
    isLoading,
    data: designData,
  } = useMutation(postRoutesFromCoords);

  useEffect(() => {
    if (shouldApiCall) {
      // generate design from map state
      postRoutesFromCoordsMutation(
        {
          element_node_list: connectionList,
        },
        {
          onSuccess: (res) => {
            const specialLayerData = [];
            for (let index = 0; index < res.length; index++) {
              const cable = res[index];
              const coords = coordsToLatLongMap(cable.geometry);
              const color = getFillColor(index);

              specialLayerData.push({
                id: cable.network_id,
                featureType: FEATURE_TYPES.POLYLINE,
                coordinates: coords,
                viewOptions: {
                  strokeColor: color,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: color,
                  fillOpacity: 1,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  radius: 30000,
                  ...HIGHLIGHTED_POLYLINE_OPTIONS,
                  zIndex: zIndexMapping.highlighted,
                  icon: undefined,
                  pin: undefined,
                },
              });
            }

            dispatch(setSpecialLayerData(specialLayerData));
            dispatch(updateMapState({ cableList: res }));
          },
          onError: (err) => {
            parseErrorMessagesWithFields(err);
          },
        }
      );
    }
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(setMapState({}));
    dispatch(setSpecialLayerData());
    // clear new auto generated design
  }, []);

  const handlePopupMinimize = useCallback(() => {
    setMinimized((val) => !val);
  }, []);

  const handleCableEdit = useCallback(
    (networkId) => () => {
      dispatch(updateAutoDesignCable(networkId));
    },
    []
  );

  if (isLoading) {
    return (
      <GisMapPopups dragId="add-gis-map-layer">
        <Box
          minWidth="450px"
          maxWidth="550px"
          backgroundColor="background.paper"
          mb={6}
        >
          <TableHeader
            title="Loading Auto design...."
            minimized={minimized}
            handlePopupMinimize={handlePopupMinimize}
            handleCloseDetails={handleCancel}
          />
        </Box>
      </GisMapPopups>
    );
  }

  return (
    <GisMapPopups dragId="add-gis-map-layer">
      <Box minWidth="450px" maxWidth="550px" backgroundColor="background.paper">
        <TableHeader
          title="Review Auto generated design"
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCancel}
        />
        {minimized ? null : (
          <Box
            sx={{
              padding: 2,
              maxHeight: "75vh",
              overflow: "auto",
            }}
          >
            {size(cableList) ? (
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Gis Length (m)</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cableList.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{row.gis_len}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            onClick={handleCableEdit(row.network_id)}
                          >
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}

            <Stack spacing={2} direction="row" justifyContent="flex-end" mt={4}>
              {/* <LoadingButton
              sx={{ minWidth: "10em" }}
              disableElevation
              variant="contained"
              disabled={false}
              onClick={() => {}}
              loading={false}
            >
              Generate Cables
            </LoadingButton> */}
              <Button
                color="error"
                sx={{ minWidth: "10em" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </GisMapPopups>
  );
};

export default ReviewAutoDesign;
