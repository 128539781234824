import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AgGridReact } from "ag-grid-react";

import size from "lodash/size";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

import AlertDetails from "./AlertDetails";
import AlertAckPopup from "./AlertAckPopup";

import { getAlertTableConfigs } from "./AlertComponents";

const AgAlertList = forwardRef(
  (
    { viewAs, alertStatus, alertList, pagination = true, height = "100%" },
    ref
  ) => {
    /**
     * Parent:
     *    AlertList
     */
    const gridRef = useRef();
    const [selectedAlertData, setSelectedAlertData] = useState(null);
    const [ackAlertId, setAckAlertId] = useState(null);

    const onGridReady = (event) => {
      event.api.sizeColumnsToFit();
    };

    useImperativeHandle(
      ref,
      () => {
        // apply filters
        const filter = (filterKey, filterValue, filterType = "equals") => {
          const currentFilters = gridRef.current.api.getFilterModel();

          gridRef.current.api.setFilterModel({
            ...currentFilters,
            [filterKey]: { type: filterType, filter: filterValue },
          });
        };

        const clearFilters = (filterKey = null) => {
          if (filterKey) {
            let currentFilters = gridRef.current.api.getFilterModel();
            delete currentFilters[filterKey];
            gridRef.current.api.setFilterModel(currentFilters);
          } else {
            gridRef.current.api.setFilterModel(null);
          }
        };

        return {
          filter,
          clearFilters,
        };
      },
      []
    );

    const defaultColDef = useMemo(() => {
      return {
        resizable: true,
        filter: "agTextColumnFilter",
        sortable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        // even when data chnages keep filter params as it is
        filterParams: { newRowsAction: "keep" },
      };
    }, []);

    const onGetAlertDetails = useCallback(
      (alertData) => () => {
        setSelectedAlertData(alertData);
      },
      []
    );

    const onAlertAck = useCallback(
      (alertId) => () => {
        setAckAlertId(alertId);
      },
      []
    );

    const handleCloseDetails = useCallback(() => {
      setSelectedAlertData(null);
      setAckAlertId(null);
    }, []);

    const alertConfigs = useMemo(() => {
      const confs = getAlertTableConfigs(
        alertStatus,
        onGetAlertDetails,
        onAlertAck,
        viewAs
      );
      return confs;
    }, [alertStatus]);

    const hasAlertData = !!size(selectedAlertData);
    const normalView = viewAs === "normal";

    return (
      <Box p={1} className="ag-theme-alpine" height={height} width="100%">
        <AgGridReact
          ref={gridRef}
          rowData={alertList}
          defaultColDef={defaultColDef}
          paginationAutoPageSize={pagination}
          pagination={pagination}
          columnDefs={alertConfigs}
          onGridReady={onGridReady}
          enableCellTextSelection
          ensureDomOrder
        />
        <Dialog onClose={handleCloseDetails} open={hasAlertData}>
          {hasAlertData ? (
            <AlertDetails
              alertData={selectedAlertData}
              onClose={handleCloseDetails}
            />
          ) : null}
        </Dialog>
        <Dialog onClose={handleCloseDetails} open={!!ackAlertId}>
          {!!ackAlertId ? (
            <AlertAckPopup
              alertStatus={alertStatus}
              alertId={ackAlertId}
              onClose={handleCloseDetails}
            />
          ) : null}
        </Dialog>
      </Box>
    );
  }
);

export default AgAlertList;
