import React from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";

import { getLogoUrl } from "utils/url.constants";

const NavLogo = () => {
  /**
   * Parent:
   *    NavigationBar
   */

  const systemConfig = useSelector((state) => state.appState.system_config);
  const logoPath = get(systemConfig, "logo_path");
  const logoUrl = logoPath
    ? window.location.origin + getLogoUrl(logoPath)
    : null;

  return (
    <img
      src={logoUrl}
      className="logo"
      style={{
        width: "100px",
      }}
    />
  );
};

export default NavLogo;
