import React, { memo, Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { Polyline, Marker, Polygon } from "@react-google-maps/api";

import get from "lodash/get";

import {
  getLayerViewData,
  getPlanningMapPosition,
} from "planning/data/planningGis.selectors";
import { getSelectedLayerKeys } from "planning/data/planningState.selectors";

import { COMMON_POLYGON_OPTIONS, LayerKeyMappings } from "../utils";
import { FEATURE_TYPES, zIndexMapping } from "../layers/common/configuration";
import TransparentMarkerIcon from "assets/markers/transparent_marker.svg";

const getElementMapLabel = (layerKey, element, showLabel) => {
  const labelFunction = get(LayerKeyMappings, [layerKey, "getMarkerLabel"]);

  if (!!showLabel && !!labelFunction) {
    const text = labelFunction(element) || null;
    return {
      className: "gis-marker-label",
      text,
    };
  }
  //
  else {
    return undefined;
  }
};

/**
 * Parent:
 *    GisMap
 */
const GisMapViewLayer = () => {
  // get list of selected layer-keys
  const mapLayers = useSelector(getSelectedLayerKeys);

  return mapLayers.map((layerKey) => {
    return <ViewLayer key={layerKey} layerKey={layerKey} />;
  });
};

const ViewLayer = ({ layerKey }) => {
  const layerData = useSelector(getLayerViewData(layerKey));
  const mapPosition = useSelector(getPlanningMapPosition);
  // marker | polyline | polygon
  const featureType = LayerKeyMappings[layerKey]["featureType"];
  const labelFunction = get(LayerKeyMappings, [layerKey, "getMarkerLabel"]);

  switch (featureType) {
    case FEATURE_TYPES.POINT:
      return layerData.map((element) => {
        const { id, hidden, coordinates, showLabel } = element;
        const viewOptions =
          LayerKeyMappings[layerKey]["getViewOptions"](element);

        const elemMapLabel = getElementMapLabel(layerKey, element, showLabel);

        if (hidden) return null;

        return (
          <Marker
            key={id}
            clickable={false}
            icon={{
              // add default icon here
              url: viewOptions.icon,
              // add label placement
              labelOrigin: viewOptions.labelOrigin || undefined,
              // Set the fixed size of the marker icon
              scaledSize: new window.google.maps.Size(
                mapPosition.iconWidth,
                mapPosition.iconHeight
              ),
              anchor: new window.google.maps.Point(
                mapPosition.anchorX,
                mapPosition.anchorY
              ),
            }}
            zIndex={zIndexMapping[layerKey]}
            position={coordinates}
            label={elemMapLabel}
          />
        );
      });

    case FEATURE_TYPES.MULTI_POLYGON:
      return layerData.map((element) => {
        const { id, hidden, coordinates, highlighted, showLabel, center } =
          element;
        const viewOptions =
          LayerKeyMappings[layerKey]["getViewOptions"](element);

        const elemMapLabel = getElementMapLabel(layerKey, element, showLabel);

        if (hidden) return null;

        return (
          <React.Fragment key={id}>
            {coordinates.map((polyCoord, ind) => {
              return (
                <Polygon
                  key={ind}
                  options={{
                    ...COMMON_POLYGON_OPTIONS,
                    ...viewOptions,
                    ...(highlighted
                      ? {
                          strokeColor: HIGHLIGHT_COLOR,
                          strokeOpacity: 1,
                          strokeWeight: 4,
                        }
                      : {}),
                    zIndex: highlighted
                      ? zIndexMapping.highlighted
                      : zIndexMapping[layerKey],
                  }}
                  paths={polyCoord}
                />
              );
            })}

            {elemMapLabel ? (
              <Marker
                clickable={false}
                zIndex={zIndexMapping[layerKey]}
                position={center}
                label={elemMapLabel}
                icon={{ url: TransparentMarkerIcon }}
              />
            ) : null}
          </React.Fragment>
        );
      });

    case FEATURE_TYPES.POLYGON:
      return layerData.map((element) => {
        const { id, hidden, coordinates, center, showLabel } = element;
        const viewOptions =
          LayerKeyMappings[layerKey]["getViewOptions"](element);

        const elemMapLabel = getElementMapLabel(layerKey, element, showLabel);

        if (hidden) return null;

        return (
          <Fragment key={id}>
            <Polygon
              options={{
                ...COMMON_POLYGON_OPTIONS,
                ...viewOptions,
                zIndex: zIndexMapping[layerKey],
              }}
              paths={coordinates}
            />
            {elemMapLabel ? (
              <Marker
                clickable={false}
                zIndex={zIndexMapping[layerKey]}
                position={center}
                label={elemMapLabel}
                icon={{ url: TransparentMarkerIcon }}
              />
            ) : null}
          </Fragment>
        );
      });

    case FEATURE_TYPES.POLYLINE:
      return layerData.map((element) => {
        const { id, hidden, coordinates, showLabel, center } = element;
        const viewOptions =
          LayerKeyMappings[layerKey]["getViewOptions"](element);

        const elemMapLabel = getElementMapLabel(layerKey, element, showLabel);

        if (hidden) return null;

        return (
          <Fragment key={id}>
            <Polyline
              options={{
                ...viewOptions,
                zIndex: zIndexMapping[layerKey],
              }}
              path={coordinates}
            />
            {elemMapLabel ? (
              <Marker
                clickable={false}
                zIndex={zIndexMapping[layerKey]}
                position={center}
                label={elemMapLabel}
                icon={{ url: TransparentMarkerIcon }}
              />
            ) : null}
          </Fragment>
        );
      });
    default:
      return null;
  }
};

export default memo(GisMapViewLayer);

const HIGHLIGHT_COLOR = "#446eca";
const lineSymbol = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  // scale: 4, // default scale is handle from polyline strokeWeight
};

const AnimatedPolyline = ({ coordinates }) => {
  const [offset, setOffset] = useState("0px");
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (offset === "0px") {
  //       setOffset("20px");
  //     } else {
  //       setOffset("0px");
  //     }
  //   }, 1000); // in milliseconds
  //   return () => clearInterval(intervalId);
  // }, [offset]);
  const options = {
    strokeColor: HIGHLIGHT_COLOR,
    strokeOpacity: 0,
    strokeWeight: 4,
    geodesic: true,
    icons: [
      {
        icon: lineSymbol,
        offset: offset,
        repeat: "20px",
      },
    ],
  };
  return (
    <Polyline
      key={offset}
      path={coordinates}
      zIndex={zIndexMapping.highlighted + 1}
      options={options}
    />
  );
};
