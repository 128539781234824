import React from "react";
import { connect } from "react-redux";

import size from "lodash/size";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";

import FieldDataTable from "./FieldDataTable";

import { tooltipFormatter } from "planning/data/catv/catv.utils";
import { Button } from "@mui/material";

class GraphsContent extends React.PureComponent {
  /**
   * Parent:
   *    GraphPopup
   */
  constructor(props) {
    super(props);

    this.state = {
      chartConfig: { ...props.chartConfig },
      showTables: false,
    };
  }

  toggleShowTables = () => {
    this.setState({ showTables: !this.state.showTables });
  };

  render = () => {
    const { chartConfig, showTables } = this.state;
    const { emptyDataMessages, graphTitle } = this.props;
    // add chart title to config : name: interface - description
    let finalChartConfig = { ...chartConfig };
    if (graphTitle) {
      finalChartConfig.title = {
        text: graphTitle,
        align: "center",
      };
    }
    // add tooltip function here as its not serializable on redux /state
    finalChartConfig.tooltip = {
      formatter: tooltipFormatter,
      shared: true,
    };

    return (
      <Box p={4} overflow="auto" maxHeight="82vh" position="relative">
        <Paper elevation={3} sx={{ paddingTop: 2 }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={finalChartConfig}
            constructorType={"stockChart"}
          />
        </Paper>
        {!!size(emptyDataMessages) ? (
          <List sx={{ listStyleType: "disc", paddingLeft: 4, color: "red" }}>
            {emptyDataMessages.map((message, ind) => (
              <ListItem key={ind} sx={{ display: "list-item" }}>
                <ListItemText primary={message} />
              </ListItem>
            ))}
          </List>
        ) : null}
        <Box mt={3}>
          <Button
            variant="outlined"
            startIcon={<VisibilityIcon />}
            onClick={this.toggleShowTables}
          >
            Show / Hide Data Tables
          </Button>
        </Box>
        {showTables ? <FieldDataTable /> : null}
      </Box>
    );
  };
}

const GraphsContentWithConnect = connect((store) => ({
  chartConfig: store.catv.chartConfig,
  emptyDataMessages: store.catv.emptyDataMessages,
}))(GraphsContent);

export default GraphsContentWithConnect;
