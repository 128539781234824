import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { DrawingManager } from "@react-google-maps/api";
import { useMutation } from "react-query";

import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import GisMapPopups from "planning/GisMap/components/GisMapPopups/GisMapPopups.js";

import { setMapState } from "planning/data/planningGis.reducer";
import { getCoordinatesFromFeature, latLongMapToCoords } from "utils/map.utils";
import { MAP_DRAWING_MODE } from "planning/GisMap/layers/common/configuration.js";
import { DRAG_ICON_WIDTH } from "utils/constant";
import { addNotification } from "redux/reducers/notification.reducer";
import { postLayerDataFromArea } from "planning/data/planning.services";

import { LAYER_KEY as PopLayerKey } from "planning/GisMap/layers/p_pop";
import { LAYER_KEY as SPopLayerKey } from "planning/GisMap/layers/p_spop";
import { showAutoDesignForm } from "planning/data/event.actions";
import { parseErrorMessagesWithFields } from "utils/api.utils";

const DrawAutoDesign = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const featureRef = useRef();

  useEffect(() => {
    // clean map ref
    return () => {
      try {
        featureRef.current.setMap(null);
      } catch (error) {}
    };
  }, []);

  const { mutate: postLayerDataFromAreaMutation, isLoading } = useMutation(
    postLayerDataFromArea
  );

  /**************************** */
  //        Handlers            //
  /**************************** */
  const handleFeatureCreate = useCallback((feature) => {
    featureRef.current = feature;
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(setMapState({}));
    try {
      featureRef.current.setMap(null);
    } catch (error) {}
  }, []);

  const handleAddComplete = () => {
    if (featureRef.current) {
      const featureCoords = getCoordinatesFromFeature(featureRef.current);
      const geometry = latLongMapToCoords(featureCoords);

      postLayerDataFromAreaMutation(
        {
          layer_keys: [PopLayerKey, SPopLayerKey],
          area_coordinates: geometry,
        },
        {
          onSuccess: (res) => {
            dispatch(showAutoDesignForm(res));
          },
          onError: (err) => {
            parseErrorMessagesWithFields(err);
          },
        }
      );
    }
    //
    else {
      dispatch(
        addNotification({
          type: "error",
          title: "Please complete polygon first.",
        })
      );
    }
  };

  return (
    <>
      <DrawingManager
        options={{
          drawingControl: false,
        }}
        drawingMode={MAP_DRAWING_MODE.polygon}
        onPolygonComplete={handleFeatureCreate}
      />
      <GisMapPopups dragId="add-gis-map-layer">
        <Paper>
          <Box
            minWidth="350px"
            maxWidth="550px"
            backgroundColor="secondary.light"
            p={2}
          >
            <Typography
              color="background.paper"
              mb={2}
              variant="h6"
              pl={`${DRAG_ICON_WIDTH - 16}px`}
            >
              Click on map to place area points on map. Complete polygon and
              adjust points.
            </Typography>
            <Stack spacing={2} direction="row">
              <LoadingButton
                sx={{ minWidth: "10em" }}
                disableElevation
                variant="contained"
                onClick={handleAddComplete}
                loading={isLoading}
              >
                Submit
              </LoadingButton>
              <Button
                color="error"
                sx={{ minWidth: "10em" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Paper>
      </GisMapPopups>
    </>
  );
};

export default DrawAutoDesign;
