import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useController } from "react-hook-form";

import get from "lodash/get";
import map from "lodash/map";
import size from "lodash/size";
import find from "lodash/find";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  getSelectedConfigurations,
  getSingleLayerConfigurationList,
} from "planning/data/planningState.selectors";
import { fetchGroupTagList } from "planning/data/catv/catv.services";

const GeneralGroupSelect = ({
  name,
  control,
  rules,
  color,
  error,
  helperText,
  disabled,
  required,
  isEdit,
}) => {
  const mapStateData = useSelector(getPlanningMapState);
  const { layerKey } = mapStateData;
  const selectedConfigurations = useSelector(getSelectedConfigurations);
  const configList = useSelector(getSingleLayerConfigurationList(layerKey));

  let groupGeneralTag;
  if (isEdit) {
    const configId = get(mapStateData, "data.configuration");
    const currentConfig = find(configList, ["id", configId]);
    groupGeneralTag = get(currentConfig, "group_general_tag");
  } else {
    const selectedConfigValue = get(selectedConfigurations, layerKey, null);
    groupGeneralTag = get(selectedConfigValue, "group_general_tag");
  }

  useEffect(() => {
    fetchTags(groupGeneralTag);
  }, [groupGeneralTag]);

  const { field } = useController({
    name,
    control,
    rules,
  });

  const {
    mutate: fetchTags,
    isLoading,
    data: groupList,
  } = useMutation(fetchGroupTagList, {
    onSuccess: (groupList) => {
      if (!isEdit) {
        field.onChange(map(groupList, "group_id"));
      }
    },
  });

  const handleClearAll = useCallback(() => {
    field.onChange([]);
  }, []);

  const handleUndo = useCallback(() => {
    field.onChange(map(groupList, "group_id"));
  }, [groupList]);

  const hasValues = !!size(field.value);

  if (groupList) {
    if (hasValues) {
      return (
        <Box>
          <Typography
            sx={{
              marginLeft: 1,
              color: "rgba(0, 0, 0, 0.6)",
            }}
            variant="caption"
          >
            General Group
          </Typography>
          <Stack spacing={1} flexDirection="row" flexWrap="wrap">
            {groupList.map((group) => {
              return (
                <Chip
                  label={group.name}
                  key={group.id}
                  variant={"filled"}
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      paddingTop: 1,
                      paddingBottom: 1,
                    },
                  }}
                />
              );
            })}
            {isEdit ? (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={handleClearAll}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </Stack>
          {error ? (
            <Typography color="error" variant="caption" sx={{ ml: 1 }}>
              {helperText}
            </Typography>
          ) : null}
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography
            sx={{
              marginLeft: 1,
              color: "rgba(0, 0, 0, 0.6)",
            }}
            variant="caption"
          >
            General Group
          </Typography>
          <Typography variant="body2" textAlign="center" py={2}>
            All General groups are cleared.
          </Typography>
          <Box textAlign="center">
            <Button variant="text" endIcon={<UndoIcon />} onClick={handleUndo}>
              Undo
            </Button>
          </Box>
          {error ? (
            <Typography color="error" variant="caption" sx={{ ml: 1 }}>
              {helperText}
            </Typography>
          ) : null}
        </Box>
      );
    }
  }
  return null;
};

export default GeneralGroupSelect;
