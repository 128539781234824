import cloneDeep from "lodash/cloneDeep";
import some from "lodash/some";

const permissionSections = [
  {
    sectionLabel: "General",
    permissions: [
      {
        label: "User",
        options: [
          {
            label: "View",
            key: "user_view",
          },
          {
            label: "Add",
            key: "user_add",
          },
          {
            label: "Edit",
            key: "user_edit",
          },
          {
            label: "Download",
            key: "user_download",
          },
          {
            label: "Upload",
            key: "user_upload",
          },
          {
            label: "Reset password",
            key: "user_reset_pass",
          },
        ],
      },
      {
        label: "Dashboards",
        options: [
          {
            label: "Survey",
            key: "survey_dash",
          },
          {
            label: "Catv Alerts",
            key: "catv_alerts_dash",
          },
          {
            label: "Favourite Graphs",
            key: "catv_fav_graph_dash",
          },
        ],
      },
      {
        label: "Region",
        options: [
          {
            label: "View",
            key: "region_view",
          },
          {
            label: "Add",
            key: "region_add",
          },
          {
            label: "Edit Details",
            key: "region_edit",
          },
          {
            label: "Edit Location",
            key: "region_edit_location",
          },
          {
            label: "Delete",
            key: "region_delete",
          },
          {
            label: "Download",
            key: "region_download",
          },
          {
            label: "Upload",
            key: "region_upload",
          },
        ],
      },
    ],
  },
  {
    sectionLabel: "Ticket",
    permissions: [
      {
        label: "Ticket",
        options: [
          {
            label: "View",
            key: "ticket_view",
          },
          {
            label: "Add",
            key: "ticket_add",
          },
          {
            label: "Edit Details",
            key: "ticket_edit",
          },
          {
            label: "Edit Location",
            key: "ticket_edit_location",
          },
        ],
      },
      {
        label: "Workorders",
        options: [
          {
            label: "View",
            key: "ticket_workorder_view",
          },
          {
            label: "Add",
            key: "ticket_workorder_add",
          },
          {
            label: "Edit Details",
            key: "ticket_workorder_edit",
          },
          {
            label: "Edit Location",
            key: "ticket_workorder_edit_location",
          },
        ],
      },
    ],
  },
  {
    sectionLabel: "Analysis",
    permissions: [
      {
        label: "Analysis",
        options: [
          {
            label: "View",
            key: "analysis_view",
          },
        ],
      },
      {
        label: "Bom Report",
        options: [
          {
            label: "View",
            key: "bom_report_view",
          },
        ],
      },
      {
        label: "Layer Data Report",
        options: [
          {
            label: "View",
            key: "layer_data_view",
          },
        ],
      },
      {
        label: "DP Customer Tagging Report",
        options: [
          {
            label: "View",
            key: "customer_tagging_view",
          },
        ],
      },
      {
        label: (
          <>
            Customer Tagging<br></br>Progress Report
          </>
        ),
        options: [
          {
            label: "View",
            key: "customer_tagging_completion_view",
          },
        ],
      },
      {
        label: (
          <>
            Customer Tagging Progress<br></br>Report - Daily Activity
          </>
        ),
        options: [
          {
            label: "View",
            key: "customer_tagging_progress_view",
          },
        ],
      },
      {
        label: "Alert Report",
        options: [
          {
            label: "View",
            key: "alert_report_view",
          },
        ],
      },
      {
        label: "FSA Home Pass Download",
        options: [
          {
            label: "View",
            key: "fsa_hp_data_download",
          },
        ],
      },
    ],
  },
  {
    sectionLabel: "Trace Back",
    permissions: [
      {
        label: "Trace Back",
        options: [
          {
            label: "View",
            key: "trace_back_view",
          },
          {
            label: "Download",
            key: "trace_back_download",
          },
        ],
      },
    ],
  },
  {
    sectionLabel: "CATV",
    permissions: [
      {
        label: "Catv User",
        options: [
          {
            label: "Catv User",
            key: "is_catv_user",
          },
        ],
      },
      {
        label: "Alert",
        options: [
          {
            label: "View List",
            key: "alert_list_view",
          },
          {
            label: "Can Close",
            key: "alert_can_close",
          },
          {
            label: "Bulk Upload Actions",
            key: "alert_action_upload",
          },
        ],
      },
    ],
  },
  {
    sectionLabel: "Planning",
    permissions: [
      {
        label: "Planning",
        options: [
          {
            label: "View",
            key: "planning_view",
          },
        ],
      },
      {
        label: "Distribution Point",
        options: [
          {
            label: "View",
            key: "p_dp_view",
          },
          {
            label: "Add",
            key: "p_dp_add",
          },
          {
            label: "Edit Details",
            key: "p_dp_edit",
          },
          {
            label: "Edit Location",
            key: "p_dp_edit_location",
          },
          {
            label: "Delete",
            key: "p_dp_delete",
          },
          {
            label: "Download",
            key: "p_dp_download",
          },
          {
            label: "Delete",
            key: "p_dp_upload",
          },
        ],
      },
      {
        label: "Spliter",
        options: [
          {
            label: "View",
            key: "p_splitter_view",
          },
          {
            label: "Add",
            key: "p_splitter_add",
          },
          {
            label: "Edit Details",
            key: "p_splitter_edit",
          },
          {
            label: "Edit Location",
            key: "p_splitter_edit_location",
          },
          {
            label: "Delete",
            key: "p_splitter_delete",
          },
          {
            label: "Download",
            key: "p_splitter_download",
          },
          {
            label: "Delete",
            key: "p_splitter_upload",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          { label: "View", key: "p_cable_view" },
          { label: "Add", key: "p_cable_add" },
          { label: "Edit Details", key: "p_cable_edit" },
          { label: "Edit Location", key: "p_cable_edit_location" },
          { label: "Delete", key: "p_cable_delete" },
          { label: "Download", key: "p_cable_download" },
          { label: "Upload", key: "p_cable_upload" },
        ],
      },
      {
        label: "Survey building",
        options: [
          { label: "View", key: "p_survey_building_view" },
          { label: "Add", key: "p_survey_building_add" },
          { label: "Edit Details", key: "p_survey_building_edit" },
          { label: "Edit Location", key: "p_survey_building_edit_location" },
          { label: "Delete", key: "p_survey_building_delete" },
          { label: "Download", key: "p_survey_building_download" },
          { label: "Upload", key: "p_survey_building_upload" },
        ],
      },
      {
        label: "Survey area",
        options: [
          { label: "View", key: "p_survey_area_view" },
          { label: "Add", key: "p_survey_area_add" },
          { label: "Edit Details", key: "p_survey_area_edit" },
          { label: "Edit Location", key: "p_survey_area_edit_location" },
          { label: "Delete", key: "p_survey_area_delete" },
          { label: "Download", key: "p_survey_area_download" },
          { label: "Upload", key: "p_survey_area_upload" },
        ],
      },
      {
        label: "Pop Location",
        options: [
          { label: "View", key: "p_pop_view" },
          { label: "Add", key: "p_pop_add" },
          { label: "Edit Details", key: "p_pop_edit" },
          { label: "Edit Location", key: "p_pop_edit_location" },
          { label: "Delete", key: "p_pop_delete" },
          { label: "Download", key: "p_pop_download" },
          { label: "Upload", key: "p_pop_upload" },
        ],
      },
      {
        label: "Sub Pop Location",
        options: [
          { label: "View", key: "p_spop_view" },
          { label: "Add", key: "p_spop_add" },
          { label: "Edit Details", key: "p_spop_edit" },
          { label: "Edit Location", key: "p_spop_edit_location" },
          { label: "Delete", key: "p_spop_delete" },
          { label: "Download", key: "p_spop_download" },
          { label: "Upload", key: "p_spop_upload" },
        ],
      },
      {
        label: "FMS",
        options: [
          { label: "View", key: "p_fms_view" },
          { label: "Add", key: "p_fms_add" },
          { label: "Edit Details", key: "p_fms_edit" },
          { label: "Edit Location", key: "p_fms_edit_location" },
          { label: "Delete", key: "p_fms_delete" },
          { label: "Download", key: "p_fms_download" },
          { label: "Upload", key: "p_fms_upload" },
        ],
      },
      {
        label: "Feeder Service Area",
        options: [
          { label: "View", key: "p_fsa_view" },
          { label: "Add", key: "p_fsa_add" },
          { label: "Edit Details", key: "p_fsa_edit" },
          { label: "Edit Location", key: "p_fsa_edit_location" },
          { label: "Delete", key: "p_fsa_delete" },
          { label: "Download", key: "p_fsa_download" },
          { label: "Upload", key: "p_fsa_upload" },
        ],
      },
      {
        label: "OLT",
        options: [
          { label: "View", key: "p_olt_view" },
          { label: "Add", key: "p_olt_add" },
          { label: "Edit Details", key: "p_olt_edit" },
          { label: "Edit Location", key: "p_olt_edit_location" },
          { label: "Delete", key: "p_olt_delete" },
          { label: "Download", key: "p_olt_download" },
          { label: "Upload", key: "p_olt_upload" },
        ],
      },
      {
        label: "Distribution Service Area",
        options: [
          { label: "View", key: "p_dsa_view" },
          { label: "Add", key: "p_dsa_add" },
          { label: "Edit Details", key: "p_dsa_edit" },
          { label: "Edit Location", key: "p_dsa_edit_location" },
          { label: "Delete", key: "p_dsa_delete" },
          { label: "Download", key: "p_dsa_download" },
          { label: "Upload", key: "p_dsa_upload" },
        ],
      },
      {
        label: "Customer Service Area",
        options: [
          { label: "View", key: "p_csa_view" },
          { label: "Add", key: "p_csa_add" },
          { label: "Edit Details", key: "p_csa_edit" },
          { label: "Edit Location", key: "p_csa_edit_location" },
          { label: "Delete", key: "p_csa_delete" },
          { label: "Download", key: "p_csa_download" },
          { label: "Upload", key: "p_csa_upload" },
        ],
      },
      {
        label: "Pole",
        options: [
          { label: "View", key: "p_pole_view" },
          { label: "Add", key: "p_pole_add" },
          { label: "Edit Details", key: "p_pole_edit" },
          { label: "Edit Location", key: "p_pole_edit_location" },
          { label: "Delete", key: "p_pole_delete" },
          { label: "Download", key: "p_pole_download" },
          { label: "Upload", key: "p_pole_upload" },
        ],
      },
      {
        label: "Chamber",
        options: [
          { label: "View", key: "p_manhole_view" },
          { label: "Add", key: "p_manhole_add" },
          { label: "Edit Details", key: "p_manhole_edit" },
          { label: "Edit Location", key: "p_manhole_edit_location" },
          { label: "Delete", key: "p_manhole_delete" },
          { label: "Download", key: "p_manhole_download" },
          { label: "Upload", key: "p_manhole_upload" },
        ],
      },
      {
        label: "Joint Closer",
        options: [
          { label: "View", key: "p_jointcloser_view" },
          { label: "Add", key: "p_jointcloser_add" },
          { label: "Edit Details", key: "p_jointcloser_edit" },
          { label: "Edit Location", key: "p_jointcloser_edit_location" },
          { label: "Delete", key: "p_jointcloser_delete" },
          { label: "Download", key: "p_jointcloser_download" },
          { label: "Upload", key: "p_jointcloser_upload" },
        ],
      },
      {
        label: "FDC",
        options: [
          { label: "View", key: "p_fdc_view" },
          { label: "Add", key: "p_fdc_add" },
          { label: "Edit Details", key: "p_fdc_edit" },
          { label: "Edit Location", key: "p_fdc_edit_location" },
          { label: "Delete", key: "p_fdc_delete" },
          { label: "Download", key: "p_fdc_download" },
          { label: "Upload", key: "p_fdc_upload" },
        ],
      },
      {
        label: "ONU",
        options: [
          { label: "View", key: "p_onu_view" },
          { label: "Add", key: "p_onu_add" },
          { label: "Edit Details", key: "p_onu_edit" },
          { label: "Edit Location", key: "p_onu_edit_location" },
          { label: "Delete", key: "p_onu_delete" },
          { label: "Download", key: "p_onu_download" },
          { label: "Upload", key: "p_onu_upload" },
        ],
      },
      {
        label: "Customer",
        options: [
          { label: "View", key: "p_customer_view" },
          { label: "Add", key: "p_customer_add" },
          { label: "Edit Details", key: "p_customer_edit" },
          { label: "Edit Location", key: "p_customer_edit_location" },
          { label: "Delete", key: "p_customer_delete" },
          { label: "Download", key: "p_customer_download" },
          { label: "Upload", key: "p_customer_upload" },
        ],
      },
      {
        label: "Switch",
        options: [
          { label: "View", key: "p_switch_view" },
          { label: "Add", key: "p_switch_add" },
          { label: "Edit Details", key: "p_switch_edit" },
          { label: "Edit Location", key: "p_switch_edit_location" },
          { label: "Delete", key: "p_switch_delete" },
          { label: "Download", key: "p_switch_download" },
          { label: "Upload", key: "p_switch_upload" },
        ],
      },
      {
        label: "Ipqam",
        options: [
          { label: "View", key: "p_ipqam_view" },
          { label: "Add", key: "p_ipqam_add" },
          { label: "Edit Details", key: "p_ipqam_edit" },
          { label: "Edit Location", key: "p_ipqam_edit_location" },
          { label: "Delete", key: "p_ipqam_delete" },
          { label: "Download", key: "p_ipqam_download" },
          { label: "Upload", key: "p_ipqam_upload" },
        ],
      },
      {
        label: "Firewall",
        options: [
          { label: "View", key: "p_firewall_view" },
          { label: "Add", key: "p_firewall_add" },
          { label: "Edit Details", key: "p_firewall_edit" },
          { label: "Edit Location", key: "p_firewall_edit_location" },
          { label: "Delete", key: "p_firewall_delete" },
          { label: "Download", key: "p_firewall_download" },
          { label: "Upload", key: "p_firewall_upload" },
        ],
      },
    ],
  },
];

// Function to create and configure permissions based on user roles and permissions
export const createPermissionConfig = (
  userPermissions, // Permissions specific to the user
  currentUserPermissions, // Permissions specific to the logged-in user (e.g., admin or superior)
  isSuperAdminUser // Boolean indicating if the user is a super admin
) => {
  // Create a deep copy of the permissionSections array to avoid mutating the original
  let clonedPermissions = cloneDeep(permissionSections);

  // Iterate through each permission section
  for (
    let sectionIndex = 0;
    sectionIndex < clonedPermissions.length;
    sectionIndex++
  ) {
    let section = clonedPermissions[sectionIndex];
    let sectionPermissions = section.permissions; // Array of permissions in the section

    // Iterate through each permission in the section
    for (
      let permissionIndex = 0;
      permissionIndex < sectionPermissions.length;
      permissionIndex++
    ) {
      let permission = sectionPermissions[permissionIndex];
      let permissionOptions = permission.options; // Array of options for the permission

      // Iterate through each option in the permission
      for (
        let optionIndex = 0;
        optionIndex < permissionOptions.length;
        optionIndex++
      ) {
        let option = permissionOptions[optionIndex];

        // If the user is a super admin, show all options
        if (isSuperAdminUser) {
          option.show = true;
        } else {
          // Show the option if the user has the permission
          option.show = !!userPermissions[option.key];

          // If the user doesn't have the permission, check if the logged-in user has it
          if (!option.show) {
            option.show = !!currentUserPermissions[option.key];
          }
        }
      }

      // Set the visibility of the permission based on whether any of its options are visible
      permission.show = isSuperAdminUser
        ? true
        : some(permissionOptions, ["show", true]);
    }

    // Set the visibility of the section based on whether any of its permissions are visible
    section.show = isSuperAdminUser
      ? true
      : some(sectionPermissions, ["show", true]);
  }

  // Return the configured permissions array
  return clonedPermissions;
};
