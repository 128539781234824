import React, { Fragment, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import find from "lodash/find";
import size from "lodash/size";

import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  Skeleton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { FormCheckbox } from "components/common/FormFields";
import { FormSelect } from "components/common/FormFields";

import { addNotification } from "redux/reducers/notification.reducer";
import { fetchUserRoles, updateUserPerm } from "gis_user/data/services";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import {
  getIsSuperAdminUser,
  getUserPermissions,
} from "redux/selectors/auth.selectors";
import { createPermissionConfig } from "gis_user/data/user.utils";

export const MIN_PERM_WIDTH = 545;
export const MIN_PERM_FIELD_WIDTH = 130;

/**
 * Parent:
 *    UserAdminForm
 */
const UserPermissions = ({
  userId,
  isSuperUser,
  userPermissions,
  role,
  onSubmit,
  goBack,
}) => {
  // login user permission
  const loginUserPermission = useSelector(getUserPermissions);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);
  const permissionConfig = createPermissionConfig(
    userPermissions,
    loginUserPermission,
    isSuperAdminUser
  );

  const dispatch = useDispatch();
  const { isFetching, data = [] } = useQuery("userRoles", fetchUserRoles, {
    staleTime: Infinity,
  });

  const { mutate, isLoading } = useMutation(updateUserPerm, {
    onSuccess: (res) => {
      dispatch(
        addNotification({
          type: "success",
          title: "User Permissions",
          text: "User permissions updated successfully",
        })
      );
      onSubmit(res);
    },
    onError: (err) => {
      const { fieldList, messageList } = parseErrorMessagesWithFields(err);
      for (let index = 0; index < fieldList.length; index++) {
        const field = fieldList[index];
        const errorMessage = messageList[index];
        dispatch(
          addNotification({
            type: "error",
            title: field,
            text: errorMessage,
          })
        );
      }
    },
  });

  const { formState, handleSubmit, watch, control, reset } = useForm({
    defaultValues: { ...userPermissions, role_id: role },
  });

  const { errors, isDirty, dirtyFields } = formState;
  // sometimes isDirty is not working so checking dirtyFields object size if dirty fields exist or not
  const isFormDirty = isDirty || size(dirtyFields);

  const handlePermissionSubmit = useCallback(
    (data) => {
      if (isFormDirty) {
        let newData;
        if (!!data.role_id) {
          newData = { role_id: data.role_id };
        } else {
          newData = {
            ...data,
            id: undefined,
            role_id: undefined,
            created_by: undefined,
            created_on: undefined,
            updated_on: undefined,
            name: undefined,
          };
        }
        mutate({ userId, data: newData });
      } else {
        onSubmit(userPermissions);
      }
    },
    [isFormDirty]
  );

  const handleRoleChange = useCallback(
    (newValue) => {
      if (newValue) {
        const currRole = find(data, ["id", newValue]);

        reset(
          {
            ...currRole,
            role_id: newValue,
            created_by: undefined,
            created_on: undefined,
            updated_on: undefined,
          },
          { keepDirty: true }
        );
      }
    },
    [data, reset]
  );

  const disabledAll = !!watch("role_id");

  if (isSuperUser) {
    return (
      <Box p={4}>
        <Stack minHeight={400} alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            You don't required any permissions.
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" pt={4}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<ArrowBackIosIcon />}
            onClick={goBack}
          >
            Back
          </Button>
          <LoadingButton
            variant="outlined"
            color="success"
            type="submit"
            endIcon={<ArrowForwardIosIcon />}
            onClick={() => onSubmit(userPermissions)}
          >
            Next
          </LoadingButton>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      p={4}
      pt={2}
      component="form"
      onSubmit={handleSubmit(handlePermissionSubmit)}
      sx={{
        overflow: "auto",
      }}
    >
      <Stack
        flex={1}
        p={4}
        justifyContent="center"
        sx={{ width: "50%", margin: "0 auto" }}
      >
        {isFetching ? (
          <Skeleton animation="wave" height={90} />
        ) : (
          <FormSelect
            label="User Role"
            name="role_id"
            control={control}
            options={data || []}
            labelKey="name"
            valueKey="id"
            error={!!errors.role_id}
            helperText={errors.role_id?.message}
            isClearable={Boolean(
              Number(process.env.REACT_APP_ENABLE_CUSTOM_PERMISSION)
            )}
            onChange={handleRoleChange}
          />
        )}
      </Stack>

      {permissionConfig.map((config) => {
        if (!config.show) return null;

        return (
          <Box key={config.sectionLabel}>
            <PermissionHeader>{config.sectionLabel}</PermissionHeader>
            <Divider />
            {config.permissions.map((permission, permIndex) => {
              if (!permission.show) return null;
              const isLastIndex = size(config.permissions) - 1 === permIndex;

              return (
                <Fragment key={permIndex}>
                  <Stack
                    spacing={2}
                    direction={{ md: "row", xs: "column" }}
                    justifyContent="center"
                    alignItems="center"
                    py={1}
                  >
                    <PermissionLabel>{permission.label}</PermissionLabel>
                    <Stack
                      flexDirection="row"
                      width={MIN_PERM_WIDTH}
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      {permission.options.map((perm) => {
                        if (!perm.show) return null;

                        return (
                          <FormCheckbox
                            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                            key={perm.key}
                            label={perm.label}
                            name={perm.key}
                            control={control}
                            error={!!errors[perm.key]}
                            helperText={errors[perm.key]?.message}
                            color="secondary"
                            disabled={disabledAll}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                  {isLastIndex ? null : <Divider />}
                </Fragment>
              );
            })}
          </Box>
        );
      })}

      <Stack flex={1} direction="row" justifyContent="space-between" pt={4}>
        <Button
          variant="outlined"
          color="error"
          startIcon={<ArrowBackIosIcon />}
          onClick={goBack}
        >
          Back
        </Button>
        <LoadingButton
          variant="outlined"
          color="success"
          type="submit"
          endIcon={<ArrowForwardIosIcon />}
          loading={isLoading}
        >
          Next
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export const PermissionLabel = (props) => {
  return (
    <Typography
      variant="h6"
      component="div"
      color="primary"
      mt="10px"
      minWidth={240}
    >
      {props.children}
    </Typography>
  );
};

export const PermissionHeader = (props) => {
  return (
    <Typography
      variant="h5"
      gutterBottom
      component="div"
      textAlign="center"
      mt={2.5}
    >
      {props.children}
    </Typography>
  );
};

export default UserPermissions;
