import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";

import { nearestPoint, featureCollection, point } from "@turf/turf";

import {
  hideElement,
  hideSpecialLayerDataById,
  removeSpecialLayerDataById,
  setMapState,
  setSpecialLayerData,
} from "./planningGis.reducer";
import {
  getAllLayersData,
  getPlanningMapState,
  getPlanningMapStateEvent,
  getPlanningSpecialLayerData,
} from "./planningGis.selectors";
import { PLANNING_EVENT } from "planning/GisMap/utils";
import remove from "lodash/remove";
import size from "lodash/size";
import { findIndex } from "lodash";
import { latLongMapToCoords, latLongMapToLineCoords } from "utils/map.utils";

export const editElementGeometry =
  ({ layerKey, elementData }) =>
  (dispatch) => {
    // fire edit geometry event
    dispatch(
      setMapState({
        event: PLANNING_EVENT.editElementGeometry,
        layerKey,
        // pass elem data to update edit icon / style based on configs
        data: {
          ...elementData,
          elementId: elementData.id,
          coordinates: elementData.coordinates,
        },
      })
    );
    // hide element that is being edited from layerData
    dispatch(hideElement({ layerKey, elementId: elementData.id }));
  };

export const showPossibleAddAssociatiation =
  ({ layerKey, elementData, listOfLayers }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showPossibleAddAssociatiation,
        layerKey,
        data: { elementId: elementData.id, elementData, listOfLayers },
      })
    );
  };

export const showAssociatiationList =
  ({ layerKey, elementId }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showAssociatedElements,
        layerKey,
        data: { elementId },
      })
    );
  };

export const associateElementOnMapClick =
  ({ layerKey, elementData, listOfLayers, extraParent }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.associateElementOnMapClick,
        layerKey,
        data: { elementData, listOfLayers, extraParent },
      })
    );
  };

export const selectElementsOnMapClick = (dispatch, getState) => {
  const event = getPlanningMapStateEvent(getState());

  if (event === PLANNING_EVENT.selectElementsOnMapClick) {
    // reset event
    dispatch(setMapState({}));
  } else {
    // start event
    dispatch(
      setMapState({
        event: PLANNING_EVENT.selectElementsOnMapClick,
      })
    );
  }
};

export const listElementsOnMap =
  ({
    elementList,
    elementData,
    filterCoords,
    isAssociationList,
    extraParent,
  }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.listElementsOnMap,
        data: {
          elementList,
          elementData,
          filterCoords,
          isAssociationList,
          extraParent,
        },
      })
    );
  };

export const showElementPortDetails =
  ({ layerKey, elementId }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showPortDetails,
        layerKey,
        data: { elementId },
      })
    );
  };

export const showSplicingView = (actionPayload) => (dispatch) => {
  dispatch(
    setMapState({
      event: PLANNING_EVENT.showSplicingView,
      layerKey: actionPayload.layerKey,
      data: actionPayload,
    })
  );
};

export const showAttachmentView =
  ({ layerKey, elementId }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showAttachmentView,
        layerKey,
        data: { elementId },
      })
    );
  };

export const showAutoDesignPopup = (dispatch) => {
  dispatch(
    setMapState({
      event: PLANNING_EVENT.drawAutoDesignBoundary,
    })
  );
};

export const showAutoDesignForm = (layerElementData) => (dispatch) => {
  dispatch(
    setMapState({
      event: PLANNING_EVENT.showAutoDesignForm,
      data: layerElementData,
    })
  );
};

export const generateAutoDesign =
  ({ designConfigurations, elementData }) =>
  (dispatch) => {
    const { secondaryLayerKey, numRings, numMaxRingElem, centerElement } =
      designConfigurations;

    let secondaryElemList = cloneDeep(elementData[secondaryLayerKey]);

    let tf_currentElement = point(centerElement.coordinates);
    // get starting secondary elem -> closest to primary elem

    let currentElement = centerElement; // start with centerElement
    let currentRing = 1; // start with first ring
    let currNumElemPerRing = 0; // how many elements we added in current ring
    // result array : [{from, to}, ...]
    let connectionList = [currentElement];

    while (true) {
      // create featureCollection of points
      let featCollectionList = [];
      for (let seInd = 0; seInd < secondaryElemList.length; seInd++) {
        const currSeElem = secondaryElemList[seInd];
        featCollectionList.push(
          point(currSeElem.coordinates, { id: currSeElem.id })
        );
      }
      let secondaryFeatureCollection = featureCollection(featCollectionList);
      // get nearest point to current element
      const tf_nearest = nearestPoint(
        tf_currentElement,
        secondaryFeatureCollection
      );

      const nearestElem = find(secondaryElemList, [
        "id",
        tf_nearest.properties.id,
      ]);
      // update conn list
      connectionList.push(nearestElem);

      currNumElemPerRing += 1;
      currentElement = nearestElem;
      tf_currentElement = point(nearestElem.coordinates);

      // remove starting elem from collection list and add to connected list
      remove(secondaryElemList, {
        id: tf_nearest.properties.id,
      });
      // validations and updates for next iteration
      if (!size(secondaryElemList)) {
        // completed processing of all secondary elems
        // go back to centerElement
        connectionList.push(centerElement);
        break;
      }
      // check if we already at end of max allowed elem for current ring
      else if (currNumElemPerRing >= numMaxRingElem) {
        // go to next ring
        currentRing += 1;
        currNumElemPerRing = 0;
        // validate num rings
        if (currentRing > numRings) {
          throw `Can not complete design with ${numMaxRingElem} per ring and only ${numRings} ring. System ran out of rings`;
        }
        // go back to center
        connectionList.push(centerElement);
        currentElement = centerElement;
        tf_currentElement = point(centerElement.coordinates);
      }
    }

    dispatch(
      setMapState({
        event: PLANNING_EVENT.reviewAutoDesign,
        data: { designConfigurations, connectionList },
        shouldApiCall: true,
      })
    );
  };

export const updateAutoDesignCable =
  (cableNetworkId) => (dispatch, getState) => {
    const state = getState();
    const currentMapState = getPlanningMapState(state);
    const specialLayerData = getPlanningSpecialLayerData(state);
    // Find the current special layer element by its network ID
    const selectedLayerElement = find(specialLayerData, ["id", cableNetworkId]);
    // Remove the current special layer data for the specified cable
    dispatch(removeSpecialLayerDataById(cableNetworkId));
    dispatch(
      setMapState({
        ...currentMapState,
        event: PLANNING_EVENT.editAutoDesignCable,
        specialLayerElementId: cableNetworkId,
        currentSpecialLayerElement: selectedLayerElement,
      })
    );
  };

export const updateAndBackToReviewAutoDesign =
  (coordinates) => (dispatch, getState) => {
    const state = getState();
    const { specialLayerElementId, currentSpecialLayerElement, data } =
      getPlanningMapState(state);
    const specialLayerElements = getPlanningSpecialLayerData(state);
    // Update the current special layer element with new coordinates
    const updatedLayerElement = {
      ...currentSpecialLayerElement,
      coordinates,
    };
    const updatedSpecialLayerData = [
      ...specialLayerElements,
      updatedLayerElement,
    ];

    // Reset special layer data and set it with updated element
    dispatch(setSpecialLayerData()); // Clears existing special layer data
    dispatch(setSpecialLayerData(updatedSpecialLayerData)); // Sets the new data

    // Update the geometry of the cable in the cable list
    let updatedCableList = [...data.cableList];
    const cableIndex = findIndex(updatedCableList, [
      "network_id",
      specialLayerElementId,
    ]);
    const updatedGeometry = latLongMapToLineCoords(coordinates);
    updatedCableList[cableIndex] = {
      ...updatedCableList[cableIndex],
      geometry: updatedGeometry,
    };

    dispatch(
      setMapState({
        event: PLANNING_EVENT.reviewAutoDesign,
        shouldApiCall: false,
        data: { ...data, cableList: updatedCableList },
      })
    );
  };
