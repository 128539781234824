import React from "react";
import { useSelector } from "react-redux";

import get from "lodash/get";

import AddGisMapLayer from "./AddGisMapLayer";
import EditGisMapLayer from "./EditGisMapLayer";
import ElementDetailsTable from "./ElementDetailsTable";
import ListElementConnections from "../layers/common/ListElementConnections";
import AddElementConnection from "../layers/common/AddElementConnection";
import ShowPossibleAddAssociation from "./ShowPossibleAddAssociation";
import ElementList from "./ElementList";
import { GisLayerForm } from "./GisLayerForm";
import ShowAssociatedElements from "./ShowAssociatedElements";
import MapEventInfoCard from "./MapEventInfoCard";
import ElementPortDetails from "./ElementPortDetails";
import SplicingView from "./SplicingView";
import LayerElementList from "./LayerElementList";
import ElementPatching from "../layers/common/ElementPatching";
import History from "./History";
import Attachments from "./Attachments";
import TracebackPopup from "./TracebackForm";
import FilterPopup from "./FilterPopup/FilterPopup";
import GenericAttrsPopup from "./Catv/GenericAttrsPopup";
import InterfaceAttrsPopup from "./Catv/InterfaceAttrsPopup";
import Graphs from "./Graphs";
import ReviewAutoDesign from "./AutoDesign/ReviewAutoDesign";
import TransportAttrsPopup from "./Catv/TransportAttrsPopup";
import DrawAutoDesign from "./AutoDesign/DrawAutoDesign";
import AutoDesignPopup from "./AutoDesign/AutoDesignPopup";
import EditAutoCableDesign from "./AutoDesign/EditAutoCableDesign";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { LayerKeyMappings, PLANNING_EVENT } from "../utils";

/**
 * Intermediate component to handle what to render on which event
 * check if LayerKeyMappings has a component for given even and layerKey
 * Render default component if not
 *
 * Parent
 *  GisMap
 *
 * Renders
 *  Forms, Popup while map is in add / edit geom. mode, Table, ..etc
 */
const GisMapEventLayer = React.memo(() => {
  const { layerKey, event } = useSelector(getPlanningMapState);

  switch (event) {
    case PLANNING_EVENT.addElementGeometry:
      return <AddGisMapLayer layerKey={layerKey} />;

    case PLANNING_EVENT.editElementGeometry:
      return <EditGisMapLayer layerKey={layerKey} />;

    case PLANNING_EVENT.addElementForm:
      const OverrideAddForm = get(LayerKeyMappings, [
        layerKey,
        PLANNING_EVENT.addElementForm,
      ]);

      if (!!OverrideAddForm) return <OverrideAddForm />;

      return <GisLayerForm layerKey={layerKey} />;

    case PLANNING_EVENT.editElementForm:
      const OverrideEditForm = get(LayerKeyMappings, [
        layerKey,
        PLANNING_EVENT.editElementForm,
      ]);

      if (!!OverrideEditForm) return <OverrideEditForm />;

      return <GisLayerForm layerKey={layerKey} />;

    case PLANNING_EVENT.showElementDetails:
      return <ElementDetailsTable layerKey={layerKey} />;

    case PLANNING_EVENT.showElementConnections:
      return <ListElementConnections layerKey={layerKey} />;

    case PLANNING_EVENT.showElementPatching:
      return <ElementPatching layerKey={layerKey} />;

    case PLANNING_EVENT.addElementConnection:
      return <AddElementConnection />;

    case PLANNING_EVENT.showPossibleAddAssociatiation:
      return <ShowPossibleAddAssociation />;

    case PLANNING_EVENT.showAssociatedElements:
      return <ShowAssociatedElements />;

    case PLANNING_EVENT.listElementsOnMap:
      return <ElementList />;

    case PLANNING_EVENT.layerElementsOnMap:
      return <LayerElementList />;

    case PLANNING_EVENT.showPortDetails:
      return <ElementPortDetails />;

    case PLANNING_EVENT.showSplicingView:
      return <SplicingView />;

    case PLANNING_EVENT.selectElementsOnMapClick:
      return (
        <MapEventInfoCard helpText="Click on map to get list of elements at that location" />
      );

    case PLANNING_EVENT.associateElementOnMapClick:
      return (
        <MapEventInfoCard helpText="Click on map to get element you want to associate with selected element" />
      );

    case PLANNING_EVENT.showHistory:
      return <History />;

    case PLANNING_EVENT.showAttachmentView:
      return <Attachments />;

    case PLANNING_EVENT.showTracebackForm:
      return <TracebackPopup />;

    case PLANNING_EVENT.showFilterPopup:
      return <FilterPopup />;

    case PLANNING_EVENT.showGenericAttrsPopup:
      return <GenericAttrsPopup />;

    case PLANNING_EVENT.showInterfaceAttrsPopup:
      return <InterfaceAttrsPopup />;

    case PLANNING_EVENT.showTransportAttrsPopup:
      return <TransportAttrsPopup />;

    case PLANNING_EVENT.showGraphPopup:
      return <Graphs />;

    case PLANNING_EVENT.drawAutoDesignBoundary:
      return <DrawAutoDesign />;

    case PLANNING_EVENT.showAutoDesignForm:
      return <AutoDesignPopup />;

    case PLANNING_EVENT.reviewAutoDesign:
      return <ReviewAutoDesign />;

    case PLANNING_EVENT.editAutoDesignCable:
      return <EditAutoCableDesign />;

    default:
      return null;
  }
});

export default GisMapEventLayer;
