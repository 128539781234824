import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import find from "lodash/find";

import { Typography, Box } from "@mui/material";

import CustomSelect from "components/common/FormFields/CustomSelect";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  getSelectedConfigurations,
  getSingleLayerConfigurationList,
} from "planning/data/planningState.selectors";
import { fetchTemplateTagList } from "planning/data/catv/catv.services";

const TemplateSelect = ({
  name,
  control,
  rules,
  color,
  error,
  helperText,
  disabled,
  required,
  isEdit,
}) => {
  const mapStateData = useSelector(getPlanningMapState);
  const { layerKey } = mapStateData;
  const selectedConfigurations = useSelector(getSelectedConfigurations);
  const configList = useSelector(getSingleLayerConfigurationList(layerKey));

  let templateTag;
  if (isEdit) {
    const configId = get(mapStateData, "data.configuration");
    const currentConfig = find(configList, ["id", configId]);
    templateTag = get(currentConfig, "template_optional_tag");
  } else {
    const selectedConfigValue = get(selectedConfigurations, layerKey, null);
    templateTag = get(selectedConfigValue, "template_optional_tag");
  }

  useEffect(() => {
    fetchTags(templateTag);
  }, [templateTag]);

  const {
    mutate: fetchTags,
    isLoading,
    data: templateList,
  } = useMutation(fetchTemplateTagList);

  return (
    <Controller
      render={({ field }) => {
        return (
          <Box>
            <CustomSelect
              label="Select Template"
              name={name}
              value={filter(templateList, (d) =>
                includes(field.value, d.template_id)
              )}
              options={templateList}
              getOptionLabel={(o) => o["name"]}
              getOptionValue={(o) => o["template_id"]}
              isMulti={true}
              isDisabled={!!disabled}
              isLoading={isLoading}
              onChange={(newValue) =>
                field.onChange(map(newValue, "template_id"))
              }
              menuPortalTarget={document.body}
            />
            {error ? (
              <Typography color="error" variant="caption" sx={{ ml: 1 }}>
                {helperText}
              </Typography>
            ) : null}
          </Box>
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

export default TemplateSelect;
