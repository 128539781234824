import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import get from "lodash/get";
import size from "lodash/size";
import { TextField, Stack, Button, Box, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { getPlanningMapStateData } from "planning/data/planningGis.selectors";
import { getSingleLayerConfigurationList } from "planning/data/planningState.selectors";

import { LAYER_KEY as CableLayerKey } from "planning/GisMap/layers/p_cable";
import { FormSelect } from "components/common/FormFields";
import { generateAutoDesign } from "planning/data/event.actions";
import { addNotification } from "redux/reducers/notification.reducer";

const AutoDesignForm = ({ closeForm }) => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const mapStateData = useSelector(getPlanningMapStateData);
  const configList = useSelector(
    getSingleLayerConfigurationList(CableLayerKey)
  );
  const elementList = get(mapStateData, "p_pop", []);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      primaryLayerKey: "p_pop",
      secondaryLayerKey: "p_spop",
      numRings: 1,
      numMinRingElem: 1,
      numMaxRingElem: 1,
      centerElement: null,
      fiberElemConfig: null,
    },
  });

  const onFormSubmit = useCallback(
    (formData) => {
      const possibleTotalElements = formData.numRings * formData.numMaxRingElem;
      const totalElements = size(get(mapStateData, formData.secondaryLayerKey));
      if (totalElements > possibleTotalElements) {
        dispatch(
          addNotification({
            type: "error",
            title: `Can not complete design with ${formData.numMaxRingElem} elements per ring and number of rings is : ${formData.numRings}. Total elements are : ${totalElements} System ran out of rings`,
          })
        );
      } else {
        dispatch(
          generateAutoDesign({
            designConfigurations: formData,
            elementData: mapStateData,
          })
        );
      }
    },
    [mapStateData]
  );

  return (
    <Box
      p={3}
      style={{ maxWidth: "450px", maxHeight: "75vh", overflow: "auto" }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormSelect
              label="Center Element"
              name={"centerElement"}
              control={control}
              options={elementList}
              error={!!get(errors, ["centerElement"])}
              helperText={get(errors, ["centerElement", "message"], "")}
              rules={{
                required: "Center Element is required",
              }}
              labelKey="name"
              valueKey="id"
              simpleValue
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              label="Fiber Element Config"
              name={"fiberElemConfig"}
              control={control}
              options={configList}
              error={!!get(errors, ["fiberElemConfig"])}
              helperText={get(errors, ["fiberElemConfig", "message"], "")}
              labelKey="config_name"
              valueKey="id"
              simpleValue
              rules={{
                required: "Fiber Element Config is required",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="full-width"
              label={"No of rings"}
              {...register("numRings")}
              error={!!get(errors, ["numRings"])}
              helperText={get(errors, ["numRings", "message"], "")}
              type={"number"}
              inputProps={{
                step: 0.0001,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="full-width"
              label={"No of min ring element"}
              {...register("numMinRingElem")}
              error={!!get(errors, ["numMinRingElem"])}
              helperText={get(errors, ["numMinRingElem", "message"], "")}
              type={"number"}
              inputProps={{
                step: 0.0001,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="full-width"
              label={"No of max ring element"}
              {...register("numMaxRingElem")}
              error={!!get(errors, ["numMaxRingElem"])}
              helperText={get(errors, ["numMaxRingElem", "message"], "")}
              type={"number"}
              inputProps={{
                step: 0.0001,
              }}
            />
          </Grid>
        </Grid>

        <Stack
          p={3}
          spacing={3}
          pb={1}
          direction="row"
          justifyContent="flex-end"
        >
          <Button sx={{ minWidth: "10em" }} color="error" onClick={closeForm}>
            Cancel
          </Button>
          <LoadingButton
            sx={{ minWidth: "10em" }}
            variant="contained"
            disableElevation
            color="success"
            type="submit"
            // loading={isLoading}
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default AutoDesignForm;
