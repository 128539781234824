import React, { useCallback, useMemo, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import get from "lodash/get";
import find from "lodash/find";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

import CustomSelect from "components/common/FormFields/CustomSelect";
import DynamicForm from "components/common/DynamicForm";
import GisMapPopups from "planning/GisMap/components/GisMapPopups";

import {
  addNewElement,
  editElementDetails,
} from "planning/data/layer.services";
import { setMapState } from "planning/data/planningGis.reducer";
import { addNotification } from "redux/reducers/notification.reducer";
import {
  fetchLayerDataThunk,
  fetchLayerListDetails,
} from "planning/data/actionBar.services";
import {
  handleLayerSelect,
  selectConfiguration,
} from "planning/data/planningState.reducer";
import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  getSelectedConfigurations,
  getSelectedRegionIds,
  getSingleLayerConfigurationList,
} from "planning/data/planningState.selectors";
import { LayerKeyMappings, PLANNING_EVENT } from "../utils";
import {
  onFetchLayerListDetailsSuccess,
  openElementDetails,
} from "planning/data/planning.actions";
import { GisElementTableLoader } from "./GisMapPopups/GisMapPopupLoader";

// remove un required fields from input data
const prepareServerData = (data, isEdit, formConfig, selectedConfigValue) => {
  let serverData = {};
  for (let index = 0; index < formConfig.sections.length; index++) {
    const { fieldConfigs } = formConfig.sections[index];
    for (let fInd = 0; fInd < fieldConfigs.length; fInd++) {
      const { field_key } = fieldConfigs[fInd];
      serverData[field_key] = data[field_key];
    }
  }
  // update add edit related fields
  if (isEdit) {
    serverData["id"] = data?.id;
    delete serverData["geometry"];
  } else {
    delete serverData["coordinates"];
    // add geometry data bcoz, formConfig dont have that field
    serverData["geometry"] = data?.geometry;
  }

  // add configuration id if have
  if (selectedConfigValue) {
    serverData["configuration"] = selectedConfigValue.id;
  }
  if (data?.association) {
    serverData["association"] = data.association;
  }

  return serverData;
};

export const GisLayerForm = ({ layerKey }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const selectedRegionIds = useSelector(getSelectedRegionIds);
  const { event, data: mapStateData } = useSelector(getPlanningMapState);
  const selectedConfigurations = useSelector(getSelectedConfigurations);
  const selectedConfigValue = get(selectedConfigurations, layerKey, null);

  const isEdit = event === PLANNING_EVENT.editElementForm;
  const transformAndValidateData = get(LayerKeyMappings, [
    layerKey,
    "transformAndValidateData",
  ]);
  const isConfigurable = !!selectedConfigValue;

  const getFormConfig = get(LayerKeyMappings, [layerKey, "getFormConfig"]);
  const formConfig = getFormConfig ? getFormConfig(selectedConfigValue) : {};
  const formTitle = get(formConfig, "title");
  /**
   * get form config from LayerKeyMappings > layerKey
   * check in form config have modifyProperty function or not
   * modifyProperty used to update fieldConfig based on Edit flag
   */
  // const formConfig = useMemo(() => {
  //   let config = get(LayerKeyMappings, [layerKey, "formConfig"]);
  //   for (let index = 0; index < config.sections.length; index++) {
  //     let section = config.sections[index];

  //     for (let secInd = 0; secInd < section.fieldConfigs.length; secInd++) {
  //       let fieldConfig = section.fieldConfigs[secInd];

  //       if (fieldConfig.modifyProperty) {
  //         const modifiedProperties = fieldConfig.modifyProperty(isEdit);
  //         merge(fieldConfig, modifiedProperties);
  //       }
  //     }
  //   }
  //   return config;
  // }, [layerKey, isEdit]);

  // update configurations in redux if not there already
  const { isLoading } = useQuery(
    "planningLayerConfigsDetails",
    fetchLayerListDetails,
    {
      staleTime: Infinity,
      // enabled: isConfigurable,
      onSuccess: (layerConfData) => {
        dispatch(onFetchLayerListDetailsSuccess(layerConfData));
      },
    }
  );

  const onSuccessHandler = ({ id }) => {
    dispatch(
      addNotification({
        type: "success",
        title: "Element operation completed Successfully",
      })
    );
    // close form
    dispatch(setMapState({}));
    dispatch(handleLayerSelect(layerKey));
    // refetch layer
    dispatch(
      fetchLayerDataThunk({
        regionIdList: selectedRegionIds,
        layerKey,
      })
    );

    dispatch(openElementDetails({ layerKey, elementId: id }));
  };

  const onErrorHandler = (err) => {
    const errStatus = get(err, "response.status");
    let notiText;
    if (errStatus === 400) {
      let errData = get(err, "response.data");
      for (const fieldKey in errData) {
        if (Object.hasOwnProperty.call(errData, fieldKey)) {
          const errList = errData[fieldKey];
          formRef.current.onError(fieldKey, get(errList, "0", ""));
        }
      }
      notiText = "Please correct input errors and submit again";
    } else {
      // maybe Internal server or network error
      // --- can not set error as not possible to clear this ---
      // formRef.current.onError(
      //   "__all__",
      //   "Something went wrong. Can not perform operation"
      // );
      notiText =
        "Something went wrong at our side. Please try again after refreshing the page.";
    }
    dispatch(
      addNotification({
        type: "error",
        title: "Operation Failed",
        text: notiText,
      })
    );
  };

  const { mutate: addElement, isLoading: isAddLoading } = useMutation(
    (mutationData) => addNewElement({ data: mutationData, layerKey }),
    {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
    }
  );

  const { mutate: editElement, isLoading: isEditLoading } = useMutation(
    (mutationData) =>
      editElementDetails({
        data: mutationData,
        layerKey,
        elementId: mapStateData?.id,
      }),
    {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
    }
  );

  const onSubmit = (data, setError, clearErrors) => {
    clearErrors();
    let validatedData = prepareServerData(
      data,
      isEdit,
      formConfig,
      selectedConfigValue
    );
    let errMessage;
    // convert data to server friendly form
    if (transformAndValidateData) {
      [validatedData, errMessage] = transformAndValidateData(
        validatedData,
        setError,
        isEdit
      );
    }

    if (errMessage) {
      dispatch(
        addNotification({
          type: "error",
          title: "Invalid Input",
          text: errMessage,
        })
      );
      return;
    }

    if (isEdit) {
      editElement(validatedData);
    } else {
      addElement(validatedData);
    }
  };

  const onClose = () => {
    dispatch(setMapState({}));
  };

  if (!formConfig) throw new Error("form config is required");

  if (isLoading) return <GisElementTableLoader />;

  return (
    <GisMapPopups>
      <Box
        minWidth="350px"
        maxWidth="550px"
        overflow="auto"
        maxHeight="85vh"
        pb={2}
      >
        <Box p={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            pb={1}
          >
            <Typography color="primary.dark" flex={1} variant="h5">
              {formTitle}
            </Typography>
            <IconButton
              sx={{
                p: 1,
              }}
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
        </Box>
        {isConfigurable ? (
          <ConfigurableSelectSection
            selectedConfigValue={selectedConfigValue}
            isEdit={isEdit}
            layerKey={layerKey}
            configId={get(mapStateData, "configuration")}
          />
        ) : null}
        <DynamicForm
          ref={formRef}
          formConfigs={formConfig}
          data={mapStateData}
          isEdit={isEdit}
          onSubmit={onSubmit}
          onCancel={onClose}
          isLoading={isEdit ? isEditLoading : isAddLoading}
        />
      </Box>
    </GisMapPopups>
  );
};

const ConfigurableSelectSection = ({
  selectedConfigValue,
  isEdit,
  layerKey,
  configId,
}) => {
  const dispatch = useDispatch();
  const configList = useSelector(getSingleLayerConfigurationList(layerKey));

  const handleConfigChange = useCallback((config) => {
    dispatch(
      selectConfiguration({
        layerKey,
        configuration: config,
      })
    );
  }, []);

  let configValue;
  if (isEdit) {
    configValue = find(configList, ["id", configId]);
  } else {
    configValue = selectedConfigValue;
  }

  return (
    <Stack spacing={2} px={2}>
      <Grid container pr={2} spacing={2} width="100%">
        <Grid item xs={12} key="configuration">
          <Box
            sx={{
              margin: "0 auto",
              width: "50%",
              paddingBottom: "24px",
              paddingTop: "24px",
            }}
          >
            <CustomSelect
              label="Select Configuration"
              isDisabled={!!isEdit}
              options={configList}
              menuPortalTarget={document.body}
              value={configValue}
              onChange={handleConfigChange}
              getOptionLabel={(o) => o.config_name}
              getOptionValue={(o) => o.id}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider />
    </Stack>
  );
};
