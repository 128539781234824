import Api from "utils/api.utils";
import {
  apiPostLayerDataFromArea,
  apiPostRoutesFromCoords,
} from "utils/url.constants";

export const postLayerDataFromArea = async (data) => {
  const res = await Api.post(apiPostLayerDataFromArea(), data);
  return res.data;
};

export const postRoutesFromCoords = async (data) => {
  const res = await Api.post(apiPostRoutesFromCoords(), data);
  return res.data;
};
