import React from "react";
import { Outlet } from "react-router-dom";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import NavigationBar from "components/NavigationBar";
import Notification from "components/common/Notification";
import WaterMark from "components/common/WaterMark";

import { fetchHealthCheck } from "./services";
import { getLoggedUserName } from "redux/selectors/auth.selectors";
import { setSystemConfig } from "redux/reducers/appState.reducer";
import getOrCreateOneSignalClient from "utils/onesignal.utils";

const Layout = () => {
  const username = useSelector(getLoggedUserName);
  const dispatch = useDispatch();
  // once user is logged in check his token status and update last login
  const { data: healthCheckData, isLoading } = useQuery(
    "healthCheck",
    fetchHealthCheck,
    {
      staleTime: 5 * 60000, // 5 minutes
      onSuccess: (res) => {
        dispatch(setSystemConfig(res));
        const isNotificationEnable = get(
          res,
          "system_config.notification_enabled",
          false
        );
        if (isNotificationEnable) {
          const OneSignalClient = getOrCreateOneSignalClient();
          OneSignalClient.loginOneSignal(username);
        }
      },
    }
  );

  return (
    <div id="layout">
      <NavigationBar />
      <Outlet />
      <Notification />
      <WaterMark />
    </div>
  );
};

export default Layout;
