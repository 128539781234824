import { createSlice } from "@reduxjs/toolkit";

import get from "lodash/get";
import has from "lodash/has";
import size from "lodash/size";
import cloneDeep from "lodash/cloneDeep";
import difference from "lodash/difference";
import findIndex from "lodash/findIndex";
import isNumber from "lodash/isNumber";
import remove from "lodash/remove";

import { handleLayerSelect, removeLayerSelect } from "./planningState.reducer";
import { logout } from "redux/reducers/auth.reducer";
import { convertLayerServerData } from "../GisMap/utils";
import { fetchLayerDataThunk } from "./actionBar.services";
import { fetchTicketWorkorderDataThunk } from "./ticket.services";
import { coordsToLatLongMap } from "utils/map.utils";
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM,
} from "components/common/Map/map.constants";
import {
  checkIfFilterActive,
  filterGisData,
  filterGisLayerData,
  getActiveLayerLabelKeys,
  updateLayerDataShowLabel,
} from "./planning.utils";

// GIS server data
export const LAYER_GIS_MASTER_DATA = {};

export const defaultLayerNetworkState = {
  isLoading: false,
  isFetched: false,
  isError: false,
  isSelected: false,
  count: 0,
  // toggle for show labels
  showLabel: false,
};

export const defaultFilterState = {
  // ie. ["L1", "L2", "RFS",...]
  status: [],
};

const initialState = {
  filters: { ...defaultFilterState },
  layerFilters: {},
  isFilterActive: false,
  // shape : { layer-key: { ...defaultLayerNetworkState } }
  layerNetworkState: {},
  // shape : { layer-key: [ {...Gis data, ...}, ...] }
  layerData: {},
  // [{featureType, ...gis data, ...view options}, ...]
  specialLayerData: [],
  // shape: { event: PLANNING_EVENT, data: { **Edit / init form data }, layerKey, minimized }
  mapState: {},
  // props use for GisMap, like center, zoom
  mapPosition: {
    center: DEFAULT_MAP_CENTER,
    zoom: DEFAULT_MAP_ZOOM,
    currentZoom: DEFAULT_MAP_ZOOM,
    currentMapBounds: [],
    iconWidth: 27,
    iconHeight: 43,
    anchorX: 14,
    anchorY: 24,
  },
  // ticket related fields
  ticketId: null,
  // shape : { **Network state, **ticket fields, area_pocket: {},
  //          work_orders: [ {**WorkOrder fields, element }, ... ] }
  ticketData: {
    isLoading: false,
    isFetched: false,
    isError: false,
  },
  // logical connection form, set all objects which will direct use in select.
  logicalConnectionForm: {
    region: null,
    // planning.constants.js -> LC_TRACE_TYPE
    // D | U | B
    traceType: null,
    from_layer: null,
    from_element: null,
    from_port: null,
    to_layer: null,
    to_element: null,
    to_port: null,
  },
};

const planningGisSlice = createSlice({
  name: "planningGis",
  initialState,
  reducers: {
    // payload : partial filters object to be updated
    setFilter: (state, { payload }) => {
      const updatedFilters = { ...state.filters, ...payload };
      // update states
      state.layerData = filterGisData(updatedFilters, LAYER_GIS_MASTER_DATA);
      state.isFilterActive = checkIfFilterActive(updatedFilters);
      state.filters = updatedFilters;

      // get list of layer keys that have label enabled from layerNetworkState and active
      const layerKeysWithLabel = getActiveLayerLabelKeys(
        state.layerNetworkState
      );

      // filter layer keys based on zoom level. for loop over layerKeysWithLabel
      for (let index = 0; index < layerKeysWithLabel.length; index++) {
        const layerKey = layerKeysWithLabel[index];
        updateLayerDataShowLabel({
          layerKey,
          elementList: state.layerData[layerKey],
          currentZoom: state.mapPosition.currentZoom,
          coordinates: state.mapPosition.currentMapBounds,
        });
      }
    },
    resetFilters: (state) => {
      state.layerData = cloneDeep(LAYER_GIS_MASTER_DATA);
      state.isFilterActive = false;
      state.filters = initialState.filters;

      // get list of layer keys that have label enabled from layerNetworkState and active
      const layerKeysWithLabel = getActiveLayerLabelKeys(
        state.layerNetworkState
      );

      // filter layer keys based on zoom level. for loop over layerKeysWithLabel
      for (let index = 0; index < layerKeysWithLabel.length; index++) {
        const layerKey = layerKeysWithLabel[index];
        updateLayerDataShowLabel({
          layerKey,
          elementList: state.layerData[layerKey],
          currentZoom: state.mapPosition.currentZoom,
          coordinates: state.mapPosition.currentMapBounds,
        });
      }
    },
    setLayerFilter: (state, { payload }) => {
      const { layerKey, filters } = payload;
      if (!filters) {
        // user is clearing filters for this layer
        delete state.layerFilters[layerKey];
      } else {
        state.layerFilters[layerKey] = filters;
      }
    },
    // payload: ticketId ( Number ) | null
    setTicketId: (state, { payload }) => {
      state.ticketId = payload;
    },
    // payload: { layerKey, elementId }
    hideElement: (state, { payload }) => {
      const { layerKey, elementId } = payload;
      // hide current element from layerData
      const elemLayerDataInd = findIndex(state.layerData[layerKey], [
        "id",
        elementId,
      ]);
      if (elemLayerDataInd !== -1) {
        state.layerData[layerKey][elemLayerDataInd].hidden = true;
      }
    },
    // payload: { layerKey, elementId }
    unHideElement: (state, { payload }) => {
      const { layerKey, elementId } = payload;
      // hide current element from layerData
      const elemLayerDataInd = findIndex(state.layerData[layerKey], [
        "id",
        elementId,
      ]);
      if (elemLayerDataInd !== -1) {
        state.layerData[layerKey][elemLayerDataInd].hidden = false;
      }
    },
    // payload : { event, layerKey, data }
    setMapState: (state, { payload }) => {
      state.mapState = { ...payload };
    },
    updateMapState: (state, { payload }) => {
      state.mapState.data = { ...state.mapState.data, ...payload };
    },
    setPopupMinimize: (state, { payload }) => {
      state.mapState.isPopupMinimize = payload;
    },
    // payload => list of selected layerKey
    // when region changes remove data for all inactive layers, so user can fetch fresh on click
    resetUnselectedLayerGisData: (state, { payload }) => {
      const allNSLayerKeys = difference(
        Object.keys(state.layerNetworkState),
        payload
      );
      for (let lk_ind = 0; lk_ind < allNSLayerKeys.length; lk_ind++) {
        const currNsKey = allNSLayerKeys[lk_ind];
        if (currNsKey !== "region") {
          // reset data
          state.layerNetworkState[currNsKey] = {
            ...defaultLayerNetworkState,
          };
          state.layerData[currNsKey] = [];
        }
      }
    },
    // shape : { center, zoom, currentZoom, isHighlighted: show marker if serched by google address }
    setMapPosition: (state, { payload }) => {
      // can be partial update
      state.mapPosition = { ...state.mapPosition, ...payload };
    },
    toggleMapPopupMinimize: (state, { payload }) => {
      // payload used to force close
      state.mapState.minimized = payload || !state.mapState.minimized;
    },
    setTicketMapHighlight: (state, { payload }) => {
      // payload: ticket-wo id
      // check previous ticket element is highlighted or not
      if (isNumber(state.ticketData.ticketHighlightedWo)) {
        const workorderInd = findIndex(state.ticketData.work_orders, [
          "id",
          state.ticketData.ticketHighlightedWo,
        ]);
        if (workorderInd !== -1) {
          state.ticketData.work_orders[workorderInd].highlighted = false;
        }
      }
      // highlight current ticket wo
      const workorderInd = findIndex(state.ticketData.work_orders, [
        "id",
        payload,
      ]);
      if (workorderInd !== -1) {
        state.ticketData.work_orders[workorderInd].highlighted = true;
        state.ticketData.ticketHighlightedWo = payload;
      }
    },
    resetTicketMapHighlight: (state) => {
      const workorderInd = findIndex(state.ticketData.work_orders, [
        "id",
        state.ticketData.ticketHighlightedWo,
      ]);
      if (workorderInd !== -1) {
        state.ticketData.work_orders[workorderInd].highlighted = false;
        state.ticketData.ticketHighlightedWo = undefined;
      }
    },
    setSpecialLayerData: (state, { payload }) => {
      if (payload) {
        for (let index = 0; index < payload.length; index++) {
          const currPayload = payload[index];
          state.specialLayerData.push(currPayload);
        }
      } else {
        state.specialLayerData = [];
      }
    },
    // payload = String, id substring
    removeSpecialLayerDataById: (state, { payload }) => {
      remove(state.specialLayerData, (d) => d.id.includes(payload));
    },
    // payload = String, id substring
    hideSpecialLayerDataById: (state, { payload }) => {
      const index = findIndex(state.specialLayerData, ["id", payload]);
      state.specialLayerData[index].hidden = true;
    },
    // payload = String, id substring
    showSpecialLayerDataById: (state, { payload }) => {
      const index = findIndex(state.specialLayerData, ["id", payload]);
      state.specialLayerData[index].hidden = false;
    },
    toggleLayerLabel: (state, { payload: layerKey }) => {
      const newValue = !state.layerNetworkState[layerKey].showLabel;
      // update layer label for current layer
      const layerKeysWithLabel = [layerKey];
      // if layer label turning ON, set real zoom level
      // if layer label turning OFF, set zoom level 0, to reset label
      let currentZoom;
      if (newValue) {
        currentZoom = state.mapPosition.currentZoom;
      } else {
        currentZoom = 0;
      }
      // filter layer keys based on zoom level. for loop over layerKeysWithLabel
      for (let index = 0; index < layerKeysWithLabel.length; index++) {
        const layerKey = layerKeysWithLabel[index];
        updateLayerDataShowLabel({
          layerKey,
          elementList: state.layerData[layerKey],
          currentZoom,
          coordinates: state.mapPosition.currentMapBounds,
        });
      }
      state.layerNetworkState[layerKey].showLabel = newValue;
    },
    handleMapBoundsChanges: (state, { payload }) => {
      // coordinates : [[lat,lng], ...]
      const { coordinates, currentZoom } = payload;
      // get list of layer keys that have label enabled from layerNetworkState and active
      const layerKeysWithLabel = getActiveLayerLabelKeys(
        state.layerNetworkState
      );

      // filter layer keys based on zoom level. for loop over layerKeysWithLabel
      for (let index = 0; index < layerKeysWithLabel.length; index++) {
        const layerKey = layerKeysWithLabel[index];
        updateLayerDataShowLabel({
          layerKey,
          elementList: state.layerData[layerKey],
          currentZoom,
          coordinates,
        });
      }

      // store current bounds and zoom
      state.mapPosition.currentZoom = currentZoom;
      state.mapPosition.currentMapBounds = coordinates;
    },
  },
  extraReducers: {
    // payload : layerKey
    [handleLayerSelect]: (state, { payload }) => {
      if (has(state, ["layerNetworkState", payload])) {
        state.layerNetworkState[payload].isSelected = true;
      }
    },
    [removeLayerSelect]: (state, { payload }) => {
      if (has(state, ["layerNetworkState", payload])) {
        state.layerNetworkState[payload].isSelected = false;
      }
    },
    // start loading
    [fetchLayerDataThunk.pending]: (state, action) => {
      const layerKey = get(action, "meta.arg.layerKey", "");
      if (has(state, ["layerNetworkState", layerKey])) {
        state.layerNetworkState[layerKey].isLoading = true;
        state.layerNetworkState[layerKey].isSelected = true;
      } else {
        // initialise new layer
        state.layerNetworkState[layerKey] = {
          ...defaultLayerNetworkState,
          isLoading: true,
          isSelected: true,
        };
        LAYER_GIS_MASTER_DATA[layerKey] = [];
        state.layerData[layerKey] = [];
      }
    },
    // fetch success
    [fetchLayerDataThunk.fulfilled]: (state, action) => {
      const layerKey = get(action, "meta.arg.layerKey", "");
      state.layerNetworkState[layerKey].isLoading = false;
      state.layerNetworkState[layerKey].isFetched = true;
      state.layerNetworkState[layerKey].count = size(action.payload);
      // convert payload coordinates into google coordinates data
      const convertedLayerGisData = cloneDeep(
        convertLayerServerData(layerKey, action.payload)
      );
      LAYER_GIS_MASTER_DATA[layerKey] = convertedLayerGisData;
      state.layerData[layerKey] = filterGisLayerData(
        state.filters,
        LAYER_GIS_MASTER_DATA[layerKey]
      );

      // get list of layer keys that have label enabled from layerNetworkState and active
      const layerKeysWithLabel = getActiveLayerLabelKeys(
        state.layerNetworkState
      );

      // filter layer keys based on zoom level. for loop over layerKeysWithLabel
      for (let index = 0; index < layerKeysWithLabel.length; index++) {
        const layerKey = layerKeysWithLabel[index];
        updateLayerDataShowLabel({
          layerKey,
          elementList: state.layerData[layerKey],
          currentZoom: state.mapPosition.currentZoom,
          coordinates: state.mapPosition.currentMapBounds,
        });
      }
    },
    // handle error
    [fetchLayerDataThunk.rejected]: (state, action) => {
      const layerKey = get(action, "meta.arg.layerKey", "");
      state.layerNetworkState[layerKey].isError = true;
    },
    [fetchTicketWorkorderDataThunk.pending]: (state, action) => {
      state.ticketData.isLoading = true;
      state.ticketData.isError = false;
    },
    [fetchTicketWorkorderDataThunk.rejected]: (state, action) => {
      state.ticketData.isLoading = false;
      state.ticketData.isFetched = true;
      state.ticketData.isError = true;
    },
    [fetchTicketWorkorderDataThunk.fulfilled]: (state, action) => {
      let ticketGisData = cloneDeep(action.payload);
      // convert ticket gis data into google coordinate data
      // convert ticket area, center
      ticketGisData.area_pocket.coordinates = coordsToLatLongMap(
        ticketGisData.area_pocket.coordinates
      );
      ticketGisData.area_pocket.center = coordsToLatLongMap([
        ticketGisData.area_pocket.center,
      ])[0];

      // convert all workorder coordinate data
      for (
        let tg_ind = 0;
        tg_ind < ticketGisData.work_orders.length;
        tg_ind++
      ) {
        const currWO = ticketGisData.work_orders[tg_ind];
        if (currWO.wo_type === "customer") {
          for (let index = 0; index < currWO.element_list.length; index++) {
            let currElement = currWO.element_list[index];
            currElement = convertLayerServerData(currElement.layer_key, [
              currElement,
            ])[0];
          }
        }
        //
        else if (currWO.wo_type === "network") {
          if (currWO.element?.id) {
            // delete type WO may not have element
            currWO.element = convertLayerServerData(currWO.layer_key, [
              currWO.element,
            ])[0];
            currWO.element.layer_key = currWO.layer_key;
          }
        }
      }

      // assign ticketGisData
      state.ticketData = ticketGisData;
      state.ticketData.isLoading = false;
      state.ticketData.isFetched = true;
      state.ticketData.isError = false;
    },
    [logout]: () => {
      return initialState;
    },
  },
});

export const {
  setFilter,
  resetFilters,
  setTicketId,
  hideElement,
  unHideElement,
  setMapState,
  setMapPosition,
  resetUnselectedLayerGisData,
  toggleMapPopupMinimize,
  setTicketMapHighlight,
  resetTicketMapHighlight,
  setSpecialLayerData,
  removeSpecialLayerDataById,
  setPopupMinimize,
  setLayerFilter,
  toggleLayerLabel,
  handleMapBoundsChanges,
  hideSpecialLayerDataById,
  showSpecialLayerDataById,
  updateMapState,
} = planningGisSlice.actions;
export default planningGisSlice.reducer;
