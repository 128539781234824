import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";

import {
  Box,
  Stack,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { FormCheckbox } from "components/common/FormFields";

import {
  createDefaultPermissions,
  getIsSuperAdminUser,
  getUserPermissions,
} from "redux/selectors/auth.selectors";
import {
  PermissionHeader,
  PermissionLabel,
  MIN_PERM_WIDTH,
  MIN_PERM_FIELD_WIDTH,
} from "./UserPermissions";
import { getRequiredFieldMessage } from "utils/constant";
import {
  addUserRole,
  deleteUserRole,
  updateUserRole,
} from "gis_user/data/services";
import { addNotification } from "redux/reducers/notification.reducer";
import { COLORS } from "App/theme";
import { createPermissionConfig } from "gis_user/data/user.utils";

/**
 * Parent:
 *    UserRoleAdminPage
 *
 * data: if data is empty object, its add otherwise edit.
 */
const UserRoleForm = ({ data, handleRoleSelect }) => {
  const loginUserPermission = useSelector(getUserPermissions);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);
  const permissionConfig = createPermissionConfig(
    loginUserPermission,
    loginUserPermission,
    isSuperAdminUser
  );

  const defaultPermissions = useSelector(createDefaultPermissions);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [showDialog, setshowDialog] = useState(null); // null or role id
  const isAdd = isEmpty(data);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
    register,
  } = useForm({
    defaultValues: isAdd ? { name: "", ...defaultPermissions } : data,
  });

  const { mutate: addUserRoleMutate, isLoading: addUserRoleLoading } =
    useMutation(addUserRole, {
      onSuccess: (res) => {
        // set new data to parent, for convert add form into edit
        handleRoleSelect(res)();
        queryClient.invalidateQueries("userRoles");
        dispatch(
          addNotification({
            type: "success",
            title: "User role",
            text: "User role added successfully",
          })
        );
      },
      onError: (err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "User role",
            text: "failed to add user role",
          })
        );
      },
    });

  const { mutate: updateUserRoleMutate, isLoading: updateUserRoleLoading } =
    useMutation(updateUserRole, {
      onSuccess: (res) => {
        queryClient.invalidateQueries("userRoles");
        dispatch(
          addNotification({
            type: "success",
            title: "User role",
            text: "User role updated successfully",
          })
        );
      },
      onError: (err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "User role",
            text: "failed to update user role",
          })
        );
      },
    });

  const { mutate: deleteMutation, isLoading: deleteLoading } = useMutation(
    deleteUserRole,
    {
      onSuccess: () => {
        // reset permission data
        handleRoleSelect(null)();
        handleDeleteClose();
        queryClient.invalidateQueries("userRoles");
        dispatch(
          addNotification({
            type: "success",
            title: "User role",
            text: "User role deleted successfully",
          })
        );
      },
      onError: () => {
        // reset permission data
        handleRoleSelect(null)();
        handleDeleteClose();
        dispatch(
          addNotification({
            type: "error",
            title: "User role",
            text: "failed to delete user role",
          })
        );
      },
    }
  );

  const handlePermissionSubmit = (data) => {
    if (isDirty) {
      if (data.id) {
        // edit
        updateUserRoleMutate({ roleId: data.id, data });
      } else {
        // add
        addUserRoleMutate(data);
      }
    }
  };

  const onDeleteConfirm = () => {
    deleteMutation(data.id);
  };

  const handleDeleteClose = () => {
    setshowDialog(null);
  };

  const handleDeleteShow = (roleId) => () => {
    setshowDialog(roleId);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handlePermissionSubmit)}>
      <Box
        p={1}
        mb={2}
        sx={{
          background: COLORS.primary.main,
          color: COLORS.primary.contrastText,
          textAlign: "center",
        }}
      >
        User Role Form
      </Box>
      <Stack
        spacing={2}
        direction={{ md: "row", xs: "column" }}
        justifyContent="center"
      >
        <PermissionLabel>Name</PermissionLabel>
        <Stack flexDirection="row" minWidth={260}>
          <TextField
            error={!!errors.name}
            label="Role Name *"
            {...register("name", {
              required: getRequiredFieldMessage("Role Name"),
            })}
            helperText={errors.name?.message}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
      </Stack>
      <Box
        p={1}
        my={2}
        sx={{
          background: COLORS.primary.main,
          color: COLORS.primary.contrastText,
          textAlign: "center",
        }}
      >
        Permissions
      </Box>

      {permissionConfig.map((config) => {
        if (!config.show) return null;

        return (
          <Box key={config.sectionLabel}>
            <PermissionHeader>{config.sectionLabel}</PermissionHeader>
            <Divider />
            {config.permissions.map((permission, permIndex) => {
              if (!permission.show) return null;
              const isLastIndex = size(config.permissions) - 1 === permIndex;

              return (
                <Fragment key={permIndex}>
                  <Stack
                    key={permIndex}
                    spacing={2}
                    direction={{ md: "row", xs: "column" }}
                    justifyContent="center"
                    alignItems="center"
                    py={1}
                  >
                    <PermissionLabel>{permission.label}</PermissionLabel>
                    <Stack
                      flexDirection="row"
                      width={MIN_PERM_WIDTH}
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      {permission.options.map((perm) => {
                        if (!perm.show) return null;

                        return (
                          <FormCheckbox
                            sx={{ minWidth: MIN_PERM_FIELD_WIDTH }}
                            key={perm.key}
                            label={perm.label}
                            name={perm.key}
                            control={control}
                            error={!!errors[perm.key]}
                            helperText={errors[perm.key]?.message}
                            color="secondary"
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                  {isLastIndex ? null : <Divider />}
                </Fragment>
              );
            })}
          </Box>
        );
      })}

      <Stack
        flex={1}
        direction="row"
        justifyContent={isAdd ? "flex-end" : "space-between"}
        p={4}
      >
        {isAdd ? null : (
          <LoadingButton
            variant="outlined"
            color="error"
            type="button"
            startIcon={<DeleteOutlineIcon />}
            onClick={handleDeleteShow(data.id)}
          >
            Delete
          </LoadingButton>
        )}
        <LoadingButton
          variant="outlined"
          color="success"
          type="submit"
          startIcon={<DoneIcon />}
          loading={addUserRoleLoading || updateUserRoleLoading}
        >
          Submit
        </LoadingButton>
      </Stack>
      <Dialog open={!!showDialog} onClose={handleDeleteClose}>
        {showDialog ? (
          <>
            <DialogTitle>Delete Role</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure to delete Role <b>{get(data, "name")}</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteClose}>Close</Button>
              <LoadingButton
                onClick={onDeleteConfirm}
                autoFocus
                loading={deleteLoading}
                color="error"
              >
                Delete
              </LoadingButton>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
    </Box>
  );
};

export default UserRoleForm;
