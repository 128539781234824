import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Polyline } from "@react-google-maps/api";

import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import get from "lodash/get";

import GisMapPopups from "planning/GisMap/components/GisMapPopups/GisMapPopups.js";

import { setMapState } from "planning/data/planningGis.reducer";
import { getCoordinatesFromFeature } from "utils/map.utils";
import { zIndexMapping } from "planning/GisMap/layers/common/configuration.js";
import { DRAG_ICON_WIDTH } from "utils/constant";
import { addNotification } from "redux/reducers/notification.reducer";
import { updateAndBackToReviewAutoDesign } from "planning/data/event.actions";
import { getPlanningMapState } from "planning/data/planningGis.selectors";

const EditAutoCableDesign = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const featureRef = useRef();
  const mapState = useSelector(getPlanningMapState);
  const coordinates = get(mapState, "currentSpecialLayerElement.coordinates");

  useEffect(() => {
    // clean map ref
    return () => {
      try {
        featureRef.current.setMap(null);
      } catch (error) {}
    };
  }, []);

  const handleEditFeatureLoad = useCallback((feature) => {
    featureRef.current = feature;
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(setMapState({}));
    try {
      featureRef.current.setMap(null);
    } catch (error) {}
  }, []);

  const handleEditComplete = () => {
    if (featureRef.current) {
      const featureCoords = getCoordinatesFromFeature(featureRef.current);
      dispatch(updateAndBackToReviewAutoDesign(featureCoords));
    } else {
      dispatch(
        addNotification({
          type: "error",
          title: "Please complete polygon first.",
        })
      );
    }
  };

  return (
    <>
      <Polyline
        path={coordinates}
        options={{
          strokeColor: "red",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "red",
          fillOpacity: 1,
          clickable: true,
          draggable: true,
          editable: true,
          strokeWeight: 4,
          zIndex: zIndexMapping.edit,
          icon: undefined,
          pin: undefined,
        }}
        onLoad={handleEditFeatureLoad}
      />

      <GisMapPopups dragId="add-gis-map-layer">
        <Paper>
          <Box
            minWidth="350px"
            maxWidth="550px"
            backgroundColor="secondary.light"
            p={2}
          >
            <Typography
              color="background.paper"
              mb={2}
              variant="h6"
              pl={`${DRAG_ICON_WIDTH - 16}px`}
            >
              Click on map to create line on map. Double click to complete.
            </Typography>
            <Stack spacing={2} direction="row">
              <LoadingButton
                sx={{ minWidth: "10em" }}
                disableElevation
                variant="contained"
                onClick={handleEditComplete}
              >
                Submit
              </LoadingButton>
              <Button
                color="error"
                sx={{ minWidth: "10em" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Paper>
      </GisMapPopups>
    </>
  );
};

export default EditAutoCableDesign;
