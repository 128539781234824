import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CloseIcon from "@mui/icons-material/Close";

const DeleteLayerErrorList = ({ errorList, onClose }) => {
  /**
   * Parent:
   *    DeleteLayer
   */

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Box />
        <Typography variant="h6" color="primary.dark">
          Errors !
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        dividers
        sx={{
          padding: 0,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            width: "88%",
            margin: "0 auto",
            marginBottom: "1em",
            // marginTop: "1em",
          }}
        >
          <Table stickyHeader size="small" aria-label="sticky dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "90px" }}>Index</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Error</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorList.map((error, index) => {
                const errorValues =
                  typeof error === "object" ? Object.values(error) : error;

                return (
                  <TableRow key={index}>
                    <TableCell sx={{ width: "90px" }}>{index + 1}</TableCell>
                    <TableCell>
                      {Array.isArray(errorValues)
                        ? errorValues.map((errorTxt) => <Box>{errorTxt}</Box>)
                        : errorValues}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Box>
  );
};

export default DeleteLayerErrorList;
