import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import size from "lodash/size";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteLayerErrorList from "./DeleteLayerErrorList";
import { addNotification } from "redux/reducers/notification.reducer";
import { layerElementsDeleteUpload } from "planning/data/layer.services";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { getSelectedRegionIds } from "planning/data/planningState.selectors";
import { fetchLayerDataThunk } from "planning/data/actionBar.services";

const UploadInput = styled("input")({
  display: "none",
});

const DeleteLayer = ({ layerConfig }) => {
  const { layer_key } = layerConfig;

  const dispatch = useDispatch();
  const selectedRegionIds = useSelector(getSelectedRegionIds);
  const [errorList, setErrorList] = useState([]);

  const { mutate: uploadMutation, isLoading } = useMutation(
    layerElementsDeleteUpload,
    {
      onError: (err) => {
        const statusCode = get(err, "response.status");
        if (statusCode === 403) {
          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.ERROR,
              title: "Delete layer data",
              text: "Permission required to delete this layer data",
            })
          );
        } else {
          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.ERROR,
              title: "Delete layer data",
              text: "Invalid file data",
            })
          );
        }
      },
      onSuccess: (res) => {
        const successList = get(res, "success_list", []);
        const errorList = get(res, "error_list", []);
        console.log("🚀 ~ file: DeleteLayer  errorList:", errorList);
        const successCount = size(successList);
        const errorCount = size(errorList);

        if (successCount && errorCount) {
          setErrorList(errorList);
          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.INFO,
              title: "Delete layer data",
              text: `${successCount} element(s) deleted successfully and  ${errorCount} element(s) are failed to delete.`,
            })
          );
        }
        //
        else if (successCount) {
          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.SUCCESS,
              title: "Delete layer data",
              text: `${successCount} element(s) deleted successfully.`,
            })
          );
        }
        //
        else if (errorCount) {
          setErrorList(errorList);
          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.INFO,
              title: "Delete layer data",
              text: `${errorCount} element(s) are failed to delete.`,
            })
          );
        }

        // refetch layer data
        dispatch(
          fetchLayerDataThunk({
            regionIdList: selectedRegionIds,
            layerKey: layer_key,
          })
        );
      },
    }
  );

  const handleOnChange = (event) => {
    const files = event.target.files;

    if (files.length) {
      const formData = new FormData();
      formData.append("file", files[0], files[0].name);

      uploadMutation({
        layerKey: layer_key,
        data: formData,
      });
    }
  };

  const handleFilePickerCancel = useCallback(() => {
    setErrorList([]);
  }, []);

  const showPopup = size(errorList);

  return (
    <>
      <LoadingButton
        startIcon={<DeleteForeverIcon />}
        color="error"
        loading={isLoading}
      >
        <label
          htmlFor={`contained-delete-${layer_key}`}
          style={{ cursor: "pointer" }}
        >
          <UploadInput
            id={`contained-delete-${layer_key}`}
            type="file"
            onChange={handleOnChange}
            accept=".xlsx, .xls, .csv"
          />
          Delete
        </label>
      </LoadingButton>
      <Dialog
        onClose={handleFilePickerCancel}
        open={showPopup}
        scroll="paper" // used to scroll content into dialog
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {showPopup ? (
          <DeleteLayerErrorList
            onClose={handleFilePickerCancel}
            errorList={errorList}
          />
        ) : null}
      </Dialog>
    </>
  );
};

export default DeleteLayer;
