import React from "react";
import { useSelector } from "react-redux";
import { Polygon, Marker, Polyline } from "@react-google-maps/api";

import { getPlanningSpecialLayerData } from "planning/data/planningGis.selectors";
import { FEATURE_TYPES } from "planning/GisMap/layers/common/configuration";
import { getSpecialLayerIcon } from "planning/GisMap/GisIcons";
import { COMMON_POLYGON_OPTIONS } from "planning/GisMap/utils";

const GisMapSpecialLayer = () => {
  const viewData = useSelector(getPlanningSpecialLayerData);

  return viewData.map((gisData, index) => {
    const {
      featureType,
      coordinates,
      viewOptions = {},
      hidden = false,
    } = gisData;

    if (hidden) return null;

    switch (featureType) {
      case FEATURE_TYPES.POINT: {
        // view option can have iconType that will directly use one of the icons from : src/planning/GisMap/GisIcons.js
        const icon = getSpecialLayerIcon(viewOptions);

        return (
          <Marker
            key={index}
            clickable={false}
            icon={icon}
            zIndex={viewOptions.zIndex}
            animation={viewOptions.animation}
            position={coordinates}
          />
        );
      }
      case FEATURE_TYPES.MULTI_POLYGON: {
        return coordinates.map((polyCoord, ind) => {
          return (
            <Polygon
              key={index + "m" + ind}
              options={{
                ...COMMON_POLYGON_OPTIONS,
                ...viewOptions,
              }}
              paths={polyCoord}
            />
          );
        });
      }
      case FEATURE_TYPES.POLYGON: {
        return (
          <Polygon
            key={index}
            options={{
              ...COMMON_POLYGON_OPTIONS,
              ...viewOptions,
            }}
            paths={coordinates}
          />
        );
      }
      case FEATURE_TYPES.POLYLINE: {
        return (
          <Polyline
            key={index}
            options={{
              ...viewOptions,
            }}
            path={coordinates}
          />
        );
      }
    }
    return null;
  });
};

export default GisMapSpecialLayer;
